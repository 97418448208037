<template>
  <div class="navbar">
    <div class="navbar-left">
      <img :src="logo" alt="Mage Logo" class="logo" />
      <h1 class="branding-text">DAZZABOT</h1>
    </div>
    <div class="navbar-right" @click="dropdownMenu">
      <img :src="avatarUrl" alt="" class="discord-user-pfp" />

      <p class="discord-user-username">{{ user.username  }}</p>
      <svg
        width="16"
        height="16"
        viewBox="0 0 12 7"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.6666 1L5.99992 5.66667L1.33325 1"
          stroke="white"
          stroke-opacity="0.64"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>

      <div class="logout-menu" @click="logout">
        <p>logout</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import defaultPfp from '../../../app_assets/default_discord_pfp.png'
export default {
  name: 'Navbar',
  computed: {
    ...mapState(['user']),
    logo() {
      return require('../../public/assets/MageLogoPNG.png');
    },
    avatarUrl() {
      return this.user.avatar ? `https://cdn.discordapp.com/avatars/${this.user.id}/${this.user.avatar}.png`:defaultPfp ;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout');
    },
    dropdownMenu() {
      const menu = document.querySelector('.logout-menu');
      if (menu.style.display === 'none') {
        menu.style.display = 'block';
      } else {
        menu.style.display = 'none';
      }
    },
  },
};
</script>
<style scoped>
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #090f18;

  color: white;
  width: 100%;
  height: 80px;
}
.logo {
  width: 60px;
  height: 18px; /* Adjust as needed */
  text-align: center;
  transform: translateY(20%);
}
.navbar h1 {
  font-size: 1.4rem;
}

.navbar-left {
  display: flex;
  align-items: center;
  gap: 14px;
}
.navbar-right {
  display: flex;
  align-items: center; /* Vertically centers the image */
  position: relative;
  justify-content: center; /* Horizontally centers the image */
  gap: 12px;
}

.branding-text {
  /* DAZZABOT */
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 21px;
  /* identical to box height */
  letter-spacing: 0.08em;

  background: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(115.51deg, #b8ffe5 48.01%, #7ef8cc 53.33%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.discord-user-username {
  /* Lucas */

  font-family: 'Inter';
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.navbar-right svg {
  margin-left: 5px;
}

.navbar-right:hover {
  cursor: pointer;
}
.navbar-right:hover svg path {
  stroke-opacity: 1;
}

.logout-menu {
  position: absolute;
  top: 40px;
  width: 100%;
  height: 100%;
  display: none;
}

.logout-menu p {
  font-family: 'Inter';
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;

  color: #ffffff;
}
</style>
