<template>
  <div class="shop-management-container">
    <h1 class="content-header shop-management-header">Shop Management</h1>
    <p class="content-header-sub">
      Manage your Shop Store items across your servers
    </p>
    <div class="shop-stats-container">
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Active Orders:
          {{ shopStats.active_orders ? shopStats.active_orders : 0 }}
        </h1>
      </div>
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Orders Weekly:
          {{ shopStats.weekly_orders ? shopStats.weekly_orders : 0 }}
        </h1>
      </div>
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Orders Monthly:
          {{ shopStats.monthly_orders ? shopStats.monthly_orders : 0 }}
        </h1>
      </div>
      <div class="shop-stat-box">
        <h1 class="stb-heading">
          Total Orders Lifetime:
          {{ shopStats.total_orders ? shopStats.total_orders : 0 }}
        </h1>
      </div>
    </div>

    <div class="shop-management-inner">
      <div class="smi-top">
        <select
          name=""
          id=""
          class="choose-shop-item dropdown"
          v-model="selectedShopItem"
          @change="changedSelectedShopItem"
        >
          <option disabled value="">Choose Shop Item</option>
          <option
            v-for="shopItem in currentShopItemsData"
            :key="shopItem.id"
            :value="shopItem"
          >
            {{ shopItem.item_name }}
          </option>
        </select>

        <button class="btn add-shop-item-btn" @click="showModal = true">
          <p>Add New Shop Item</p>
        </button>
        <div class="shop-setting-input">
          <label for="shop-item-name" class="label">Shop Status</label>
          <select
            name=""
            id=""
            class="choose-shop-status dropdown"
            v-model="shopStats.shop_open"
            @change="updateShopStatus"
          >
            <option value="false">Closed</option>
            <option value="true">Open</option>
          </select>
        </div>
      </div>
      <div class="smi-body" v-if="selectedShopItem">
        <p class="smi-body-heading content-header-sub">Shop Item Settings</p>

        <div class="smi-body-settings">
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Item ID</label>
            <input
              type="text"
              id="shop-item-name"
              class="input"
              v-model="selectedShopItemUpdatedCopy.id"
              readonly
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Shop Item Name</label>
            <input
              type="text"
              id="shop-item-name"
              class="input"
              v-model="selectedShopItemUpdatedCopy.item_name"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Shop Item Type</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdatedCopy.item_type"
            >
              <option value="npc">npc</option>
            </select>
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">{{
              shopItemType === 'npc' ? 'Spawnable Type' : 'Shop Package'
            }}</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdatedCopy.item_name_xml"
            >
              <option v-for="npc in Npcs" :key="npc.value" :value="npc.value">
                {{ npc.name }}
              </option>
            </select>
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label"
              >Item Price Per Restart</label
            >
            <input
              type="number"
              id="shop-item-name"
              class="input"
              min="1"
              max="1000000"
              v-model="selectedShopItemUpdatedCopy.item_price"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-quantity" class="label">Item Quantity</label>
            <input
              type="number"
              id="shop-item-quantity"
              class="input"
              min="0"
              max="1000000"
              v-model="selectedShopItemUpdatedCopy.item_quantity"
            />
          </div>

          <div class="shop-setting-input">
            <label for="shop-item-name" class="label"
              >Max Item Duration (restarts)</label
            >
            <input
              type="number"
              id="shop-item-name"
              class="input"
              min="1"
              max="100000"
              v-model="selectedShopItemUpdatedCopy.max_duration"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Allow Extensions</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdatedCopy.allow_extension"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label"
              >Extension Price per restart</label
            >
            <input
              type="number"
              id="shop-item-name"
              class="input"
              min="1"
              max="100000"
              v-model="selectedShopItemUpdatedCopy.extension_price_per_restart"
            />
          </div>
          <div class="shop-setting-input">
            <label for="shop-item-name" class="label">Item Enabled</label>
            <select
              name=""
              id=""
              class="dropdown"
              v-model="selectedShopItemUpdatedCopy.enabled"
            >
              <option value="false">False</option>
              <option value="true">True</option>
            </select>
          </div>
          <div class="shop-setting-input">
            <button @click="openRestrictedServersModal">
              Edit Restricted Servers
            </button>
          </div>
        </div>

        <div class="smi-body-footer">
          <button
            class="modal-btn delete-btn cancel-btn"
            @click="deleteShopItem"
          >
            Delete Item
          </button>
          <button class="modal-btn save-settings-btn" @click="updateShopItem()">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </div>
    </div>

    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Shop Items</p>
          <p class="modal-main-heading">Add New Shop Item</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input"
                >Shop Item Name</label
              >
              <input
                type="text"
                id="shop-item-name"
                class="input"
                v-model="newShopItem.item_name"
              />
            </div>
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input"
                >Shop Item Type</label
              >
              <select
                name=""
                id=""
                class="dropdown"
                v-model="newShopItem.item_type"
              >
                <option value="npc">npc</option>
              </select>
            </div>
          </div>
          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input">{{
                newShopItem.item_type === 'npc'
                  ? 'Spawnable Type'
                  : 'Shop Package'
              }}</label>

              <select
                name=""
                id=""
                class="dropdown"
                v-model="newShopItem.item_name_xml"
                :disabled="!newShopItem.item_type"
              >
                <option v-for="npc in Npcs" :key="npc.value" :value="npc.value">
                  {{ npc.name }}
                </option>
              </select>
            </div>
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label required-input"
                >Item Price Per Restart</label
              >
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="1"
                max="1000000"
                v-model="newShopItem.item_price"
              />
            </div>
          </div>
          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label">Item Quantity</label>
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="0"
                max="1000000"
                v-model="newShopItem.item_quantity"
              />
            </div>

            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label"
                >Max Item Time (restarts)</label
              >
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="1"
                max="100000"
                v-model="newShopItem.max_time"
              />
            </div>
          </div>

          <div class="modal-row">
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label">Allow Extensions</label>
              <select
                name=""
                id=""
                class="dropdown"
                v-model="newShopItem.allow_extension"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
            <div class="shop-setting-input modal-input">
              <label for="shop-item-name" class="label"
                >Extension Price per restart</label
              >
              <input
                type="number"
                id="shop-item-name"
                class="input"
                min="1"
                max="100000"
                v-model="newShopItem.extension_price_per_restart"
              />
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardAddNewItem">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="addNewShopItem"
            :disabled="
              !newShopItem.item_name ||
              !newShopItem.item_type ||
              !newShopItem.item_name_xml ||
              !newShopItem.item_price
            "
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Add New Item</p>
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      :show="showRestrictedServersModal"
      @update:show="showRestrictedServersModal = $event"
    >
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Restricted Servers</p>
          <p class="modal-main-heading">Add Restricted Servers For This Item</p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-body-restricted-servers-container">
            <div
              v-for="server in selectedShopItemUpdatedCopy.restricted_servers"
              :key="server.id"
              class="modal-checkbox-select"
              :class="{
                'active-checked': server.selected,
              }"
            >
              <input
                type="checkbox"
                :id="server.id"
                class="checkbox"
                :checked="server.selected"
                @change="server.selected = !server.selected"
              />
              <label :for="server.id" class="checkbox-label"></label>

              <p class="checkbox-name">{{ server.name }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button
            class="modal-btn cancel-btn"
            @click="discardRestrictedServersChanges"
          >
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="updateRestrictedServers"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from './Modal.vue';
import SpawnableTypeData from '../../../data/dayz_items_data.json';
export default {
  name: 'ShopManagement',
  components: {
    Modal,
  },
  data() {
    return {
      selectedShopItem: '',
      shopItemType: 'npc',
      shopStatus: 'False',
      showModal: false,
      newShopItem: {},
      currentShopItemsData: [],
      shopStats: {},
      showRestrictedServersModal: false,
      selectedShopItemUpdatedCopy: {},
    };
  },

  computed: {
    Npcs() {
      let survivor_names = SpawnableTypeData.Survivors.map(survivor => ({
        name: survivor,
        value: survivor,
      }));

      let vehicles = Object.entries(SpawnableTypeData.vehicles).flatMap(
        ([vehicle, details]) => {
          if (!details.variants || details.variants.length === 0) {
            // make the name the in_game_name plus the variant
            return { name: details.in_game_name, value: vehicle };
          }

          return details.variants.map(variant => {
            return {
              name: details.in_game_name + ` ${variant}`,
              value: `${vehicle}_${variant}`,
            };
          });
        }
      );

      return [...survivor_names, ...vehicles];
    },
    itemQuantity: {
      get() {
        return this.selectedShopItem.item_quantity;
      },
      set(value) {
        this.selectedShopItem.item_quantity = value === '' ? null : value;
      },
    },
    MaxDuration: {
      get() {
        return this.selectedShopItem.max_time;
      },
      set(value) {
        this.selectedShopItem.max_time = value === '' ? null : value;
      },
    },
  },

  methods: {

    async discardRestrictedServersChanges() {
      this.showRestrictedServersModal = false;
      this.selectedShopItemUpdatedCopy.restricted_servers = this.$store.state.client_discord_server ?
        this.$store.state.client_discord_server.servers.map(server => {
          return {
            id: server.id,
            name: server.server_identifier,
            selected: this.selectedShopItem.restricted_servers.includes(
              server.id
            ),
          };
        })
        : [];
    },

    async updateRestrictedServers() {
      // send request to api to update the restricted servers for the selected shop item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          id: this.selectedShopItemUpdatedCopy.id,
          restricted_server_ids: this.selectedShopItemUpdatedCopy.restricted_servers
            .filter(server => server.selected)
            .map(server => server.id),
        },
        show_message_on_success: true,
      });

      if (response) {
        this.showRestrictedServersModal = false;
      }
    },

    async changedSelectedShopItem() {
      this.selectedShopItemUpdatedCopy = JSON.parse(
        JSON.stringify(this.selectedShopItem)
      );
      this.selectedShopItemUpdatedCopy.restricted_servers = this.$store.state.client_discord_server ?
        this.$store.state.client_discord_server.servers.map(server => {
         
          return {
            id: server.id,
            name: server.server_identifier,
            selected: Object.values(this.selectedShopItem.restricted_servers).includes(server.id)
          };
        })
        : [];
    },

    async openRestrictedServersModal() {
      this.showRestrictedServersModal = true;
    },

    async updateShopStatus() {
      // send request to api to update the shop status
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/shop/config`,
        data: {
          shop_open: this.shopStats.shop_open,
        },
        show_message_on_success: true,
      });

      if (response) {
        this.$store.commit('UPDATE_SHOP_OPEN', response.shop_open);
        this.shopStats.shop_open = response.shop_open;
      }
    },

    async addNewShopItem() {
      let testObject = JSON.stringify(this.newShopItem);
      let parsedObject = JSON.parse(testObject);
      parsedObject.item_price = null;

      // send request to the api to add new shop item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'POST',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          ...this.newShopItem,
        },
        show_message_on_success: true,
      });

      if (response) {
        this.showModal = false;
        let latestShopItem = response.shop_item;
        this.currentShopItemsData.push(latestShopItem);
      }
    },

    async fetchShopItems() {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        show_message_on_success: false,
      });

      if (response) {
        this.currentShopItemsData = response.shop_items;
      }
    },

    async updateShopItem() {
      // send request to api to update the shop item with the updated field

      let changedConfig = JSON.parse(JSON.stringify(this.selectedShopItemUpdatedCopy));


      // for key in changed config, if the value is the same as this.selectedShopItem, dont include it, we only want updated values
      for (let key in changedConfig) {

        if (changedConfig[key] === this.selectedShopItem[key] || key === 'restricted_servers' ) {
          delete changedConfig[key];
        }
      }



      // send request to the api to update the shop item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          id: this.selectedShopItemUpdatedCopy.id,
          ...changedConfig,
        },
        show_message_on_success: true,
      });

      if (response) {
          this.selectedShopItem = response.shop_item;
          // find the index of the updated shop item in the currentShopItemsData array
          let index = this.currentShopItemsData.findIndex(
            item => item.id === this.selectedShopItem.id
          );

          // update the currentShopItemsData array with the updated shop item
          this.currentShopItemsData[index] = this.selectedShopItem;
      }


    },

    async deleteShopItem() {
      // send request to api to delete the selected shop item
      const response = await this.$store.dispatch('sendRequest', {
        method: 'DELETE',
        url: `${this.$store.state.apiUrl}/api/shop/items`,
        data: {
          id: this.selectedShopItem.id,
        },
        show_message_on_success: true,
      });

      if (response) {
        // remove the deleted shop item from the currentShopItemsData array
        this.currentShopItemsData = this.currentShopItemsData.filter(
          item => item.id !== this.selectedShopItem.id
        );
        this.selectedShopItem = '';
      }
    },

    async fetchShopStats() {
      let cachedStats = this.$store.state.shopStatistics
        ? this.$store.state.shopStatistics
        : null;

      if (cachedStats) {
        this.shopStats = cachedStats;
      
        return;
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'GET',
        url: `${this.$store.state.apiUrl}/api/shop/stats`,
        show_message_on_success: false,
      });

      if (response) {
        this.shopStats = response.shop_stats;
        this.$store.commit('SET_SHOP_STATISTICS', response.shop_stats);
      }
    },

    discardAddNewItem() {
      this.showModal = false;
      this.newShopItem = {};
    },
  },

  async mounted() {
    await this.fetchShopItems();
    await this.fetchShopStats();
  },
};
</script>

<style scoped>
.shop-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}

.shop-management-header {
  margin-bottom: 8px;
}

.shop-stats-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, max-content));
  column-gap: 50px;
  row-gap: 12px;
}

.stb-heading {
  font-size: 1.6rem;
}

.shop-stat-box {
  background-color: rgba(195, 218, 252, 0.08);
  display: flex;
  height: 100%;
  justify-content: center;
  color: white;
  border-radius: 16px;
  padding: 4px 12px;
}

.shop-management-inner {
  margin-top: 24px;
}

.smi-top {
  display: flex;
  gap: 20px;
  align-items: flex-end;
}
.add-shop-item-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.smi-body-heading {
  margin-top: 36px;
}

.smi-body-settings {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, max-content));
  column-gap: 20px;
  row-gap: 12px;
}

.shop-setting-input {
  flex-direction: column;
  text-align: left;
  display: flex;
  row-gap: 4px;
  justify-content: center;
}

.input {
  background-color: rgba(195, 218, 252, 0.08);
  box-sizing: border-box;
  display: inline-block;
  padding: 8px 12px;
  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
}
.input:focus,
.dropdown:focus {
  outline: none;
}

.dropdown option {
  color: black;
}

.input option {
  color: black;
}

.label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  /* identical to box height */
  /* letter-spacing: 0.08em; */
  padding-left: 5px;

  color: #bbced8;
}

.add-shop-item-btn,
.choose-shop-item {
  height: max-content;
}

.modal-body-content-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.smi-body-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  column-gap: 12px;
}
.save-settings-btn, .delete-btn {
  width: max-content;
}



.modal-body-restricted-servers-container {
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-right: 8px;
}
</style>
