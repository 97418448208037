<template>
  <div class="item-auto-ban-management-container">
    <div class="item-ban-management-top">
      <h1 class="content-header">Item Ban Management</h1>
      <p class="content-header-sub">
        Manage your banned items across your servers
      </p>
    </div>

    <div class="item-ban-management-options">
      <select
        name=""
        id=""
        class="choose-shop-item dropdown"
        v-model="selectedBannedItem"
        @change="updateSelectedItemSelect"
      >
        <option disabled value="">Edit Banned Item</option>
        <option
          v-for="banneditem in bannedItems"
          :key="banneditem.id"
          :value="banneditem"
        >
          {{ banneditem.item_name }}
        </option>
      </select>
      <button
        class="btn new-banned-item-btn"
        @click="openAddBannedItemModal"
        :disabled="!itemAddBtnEnabled"
      >
        <p>Add New Banned Item</p>
      </button>
    </div>

    <div class="item-ban-management-section" v-if="selectedBannedItem">
        <div class="modal-option">
              <label for="item name" class="required-input"
                >Item Name (Exactly like logs)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="selectedUpdateBannedItemUpdated.item_name"
                maxlength="100"
              />
            </div>
        <div class="modal-option">
              <label for="ban_duration" class=""
                >Ban Duration (Days)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="selectedUpdateBannedItemUpdated.ban_duration"
                maxlength="100"
              />
            </div>

            <div class="modal-option">
              <label for="item name" class="">Bail Price</label>
              <input
                type="number"
                class="modal-option-input"
                v-model="selectedUpdateBannedItemUpdated.bail_price"
                min="1"
                max="1000000000"
              />
            </div>
            <div class="modal-option">
              <label for="auto_bail" class="">Auto Bail</label>
              <select
                name=""
                class="dropdown"
                v-model="selectedUpdateBannedItemUpdated.auto_bail"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>

            <div class="modal-option">
              <label for="enabled" class="">Enabled</label>
              <select
                name=""
                class="dropdown"
                v-model="selectedUpdateBannedItemUpdated.enabled"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
            <button
        class="btn edit-servers-btn"
        @click="openEditItemBanServersModal"
      >
        <p>Edit Servers</p>
      </button>

            <div class="btn-container-banned-item-update"> 
            <button class="modal-btn cancel-btn" @click="discardUpdatedChanges">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="UpdateSelectedBannedItem"
            :disabled="
              !itemUpdateBtnEnabled
            "
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save</p>
          </button></div>
    </div>

    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">Banned Items</p>
          <p class="modal-main-heading">Add New Banned Item</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-row-container">
          <div class="modal-row">
            <div class="modal-option">
              <label for="item name" class="required-input"
                >Item Name (Exactly like logs)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="selectedBannedItemUpdated.item_name"
                maxlength="100"
              />
            </div>
            <div class="modal-option">
              <label for="item name" class="">Ban Duration (Days)</label>
              <input
                type="number"
                class="modal-option-input"
                v-model="selectedBannedItemUpdated.ban_duration"
                min="0"
                max="365"
              />
            </div>
          </div>
          <div class="modal-row">
            <div class="modal-option">
              <label for="item name" class="">Bail Price</label>
              <input
                type="number"
                class="modal-option-input"
                v-model="selectedBannedItemUpdated.bail_price"
                min="1"
                max="1000000000"
              />
            </div>
            <div class="modal-option">
              <label for="auto_bail" class="">Auto Bail</label>
              <select
                name=""
                class="dropdown"
                v-model="selectedBannedItemUpdated.auto_bail"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
          </div>
          <div class="modal-row">
            <div class="modal-option">
              <label for="enabled" class="">Enabled</label>
              <select
                name=""
                class="dropdown"
                v-model="selectedBannedItemUpdated.enabled"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardNewBannedItem">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="AddNewBannedItem"
            :disabled="
              !itemAddBtnEnabled || !selectedBannedItemUpdated.item_name
            "
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      :show="ShowEditBannedItemServersModal"
      @update:show="ShowEditBannedItemServersModal = $event"
    >
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Edit Servers</p>
          <p class="modal-main-heading">Edit The Servers This Item Is Banned On</p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-body-restricted-servers-container">
            <div
              v-for="server in selectedUpdateBannedItemUpdated.servers"
              :key="server.id"
              class="modal-checkbox-select"
              :class="{
                'active-checked': server.selected,
              }"
            >
              <input
                type="checkbox"
                :id="server.id"
                class="checkbox"
                :checked="server.selected"
                @change="server.selected = !server.selected"
              />
              <label :for="server.id" class="checkbox-label"></label>
              <p class="checkbox-name">{{ server.name }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button
            class="modal-btn cancel-btn"
            @click="discardSelectedBannedItemServers"
          >
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="updateSelectedBannedItemServers"
            :disabled="!updateServersBtnEnabled"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>

  </div>
</template>

<script>
// import vue store
import { mapState } from 'vuex';
import Modal from './Modal.vue';
export default {
  name: 'ItemAutoBanManagement',

  components: { Modal },

  data() {
    return {
      bannedItems: [],
      selectedBannedItem: '',
      selectedBannedItemUpdated: {},
      selectedUpdateBannedItemUpdated: '',
      showModal: false,
      showEditModal: false,
      itemUpdateBtnEnabled: true,
      itemAddBtnEnabled: true,
      ShowEditBannedItemServersModal: false,
      updateServersBtnEnabled: true,
    };
  },

  computed: {
  },

  methods: {
    async loadBannedItems() {
      try {
        // Check for cache
        if (
          this.$store &&
          this.$store.state &&
          this.$store.state.bannedItems &&
          this.$store.state.bannedItems.banned_items
        ) {
          // Check if the last cache time is less than 10 minutes
          if (
            this.$store.state.bannedItems.last_cache_timestamp >
            Date.now() - 600000
          ) {
            this.bannedItems = this.$store.state.bannedItems.banned_items;
            return;
          }
        }

        // If no cache or cache is old, fetch from server
        const response = await this.$store.dispatch('sendRequest', {
          method: 'GET',
          url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
          show_message_on_success: false,
        });

        if (response) {
          this.bannedItems = response.item_bans;
          this.$store.commit('setBannedItems', {
            banned_items: response.item_bans,
            last_cache_timestamp: Date.now(),
          });
        }
      } catch (error) {
        console.error('Error occurred while loading banned items:', error);
      }
    },

    openEditItemBanServersModal() {
      this.ShowEditBannedItemServersModal = true;
    },

    openAddBannedItemModal() {
      this.showModal = true;
    },

    discardNewBannedItem() {
      this.showModal = false;
      this.selectedBannedItemUpdated = {};
    },

    async AddNewBannedItem() {
      this.itemAddBtnEnabled = false;
      const response = await this.$store.dispatch('sendRequest', {
        method: 'POST',
        url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
        data: this.selectedBannedItemUpdated,
      });

      if (response) {
        this.bannedItems.push(response.item_ban);
        this.$store.commit('setBannedItems', {
          banned_items: this.bannedItems,
          last_cache_timestamp: Date.now(),
        });
      }
      this.showModal = false;
      this.selectedBannedItemUpdated = {};
      this.itemAddBtnEnabled = true;
    },

    updateSelectedItemSelect() {

      this.selectedUpdateBannedItemUpdated = JSON.parse(
        JSON.stringify(this.selectedBannedItem)
      );
      this.selectedUpdateBannedItemUpdated.servers = this.$store.state.client_discord_server.servers.map(
        (server) => {
          return {
            id: server.id,
            name: server.server_identifier,
            selected: this.selectedBannedItem.servers.some(
              (selectedServer) => selectedServer.id === server.id
            ),
          };
        }
      );

    },

    async updateSelectedBannedItemServers() {

        this.updateServersBtnEnabled = false;

        let selected_servers = this.selectedUpdateBannedItemUpdated.servers.filter(
          (server) => server.selected
        );

        let selected_server_ids = selected_servers.map((server) => server.id);

        const response = await this.$store.dispatch('sendRequest', {
          method: 'PUT',
          url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
          data: {
            id: this.selectedBannedItem.id,
            server_ids: selected_server_ids,
          },
        });
        if (response) {
            const index = this.bannedItems.findIndex(
              (item) => item.id === this.selectedBannedItem.id
            );
            this.bannedItems[index] = response.item_ban;
            this.$store.commit('setBannedItems', {
              banned_items: this.bannedItems,
              last_cache_timestamp: Date.now(),
            });
          }
          this.updateServersBtnEnabled = true;
          this.ShowEditBannedItemServersModal = false;
    },

    async UpdateSelectedBannedItem() {
        this.itemUpdateBtnEnabled = false;

        let changedConfig = JSON.parse(JSON.stringify(this.selectedUpdateBannedItemUpdated))

        let changed_data = {
            id: this.selectedBannedItem.id,
        }

        for (let key in changedConfig) {
            if (changedConfig[key] !== this.selectedBannedItem[key] && key !== 'servers') {
                changed_data[key] = changedConfig[key]
            }
        }

        const response = await this.$store.dispatch('sendRequest', {
            method: 'PUT',
            url: `${this.$store.state.apiUrl}/api/item-auto-ban`,
            data: changed_data,
        });
    
        if (response) {
            const index = this.bannedItems.findIndex(
            (item) => item.id === this.selectedBannedItem.id
            );
            this.bannedItems[index] = response.item_ban;
            this.$store.commit('setBannedItems', {
            banned_items: this.bannedItems,
            last_cache_timestamp: Date.now(),
            });
        }
        this.itemUpdateBtnEnabled = true;
        this.selectedBannedItem = response.item_ban;
    },

    discardUpdatedChanges() {
      this.selectedUpdateBannedItemUpdated = JSON.parse(
        JSON.stringify(this.selectedBannedItem)
      );
    },

    discardSelectedBannedItemServers() {
        this.selectedUpdateBannedItemUpdated.servers = this.$store.state.client_discord_server.servers.map(
            (server) => {
            return {
                id: server.id,
                name: server.server_identifier,
                selected: this.selectedBannedItem.servers.some(
                (selectedServer) => selectedServer.id === server.id
                ),
            };
            }
        );
    }
  },

  created() {
    this.loadBannedItems();
  },
};
</script>

<style scoped>
.item-auto-ban-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}

.item-ban-management-options {
  display: flex;
  gap: 24px;
  align-items: center;
}

.new-banned-item-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.edit-servers-btn {
    box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content
}

.item-ban-management-section {
    margin-top: 24px;
    display: grid;
    /* make the grid auto responsive, as many columns as it can fit, each column 200px max */
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    row-gap: 16px;
    align-items: end;
}

.btn-container-banned-item-update {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}
</style>
