<template>
  <div class="dashboard-home-page">
    <div class="top-statistics">
      <div class="servers-linked dh-home-box">
        <img
          src="../../public/assets/database-icon.png"
          alt=""
          class="dash-icon"
        />
        <p class="dh-box-text">Servers</p>
        <div class="flex-text">
          <p class="servers-linked-text">{{ serversCount }}</p>
          <svg
            @click="openModal('link-server')"
            class="add-icon"
            width="64px"
            height="24px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#000000"
            stroke-width="0.00024000000000000003"
          >
            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></g>
            <g id="SVGRepo_iconCarrier">
              <path
                d="M11 8C11 7.44772 11.4477 7 12 7C12.5523 7 13 7.44772 13 8V11H16C16.5523 11 17 11.4477 17 12C17 12.5523 16.5523 13 16 13H13V16C13 16.5523 12.5523 17 12 17C11.4477 17 11 16.5523 11 16V13H8C7.44771 13 7 12.5523 7 12C7 11.4477 7.44772 11 8 11H11V8Z"
                fill="#6fe0af"
              ></path>
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z"
                fill="#6fe0af"
              ></path>
            </g>
          </svg>
        </div>
      </div>
      <div class="nitrado-link dh-home-box">
        <svg
          class="dash-icon"
          viewBox="0 0 1024 1024"
          xmlns="http://www.w3.org/2000/svg"
          fill="#000000"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            <path
              fill="#6fe0af"
              d="M640 384v64H448a128 128 0 00-128 128v128a128 128 0 00128 128h320a128 128 0 00128-128V576a128 128 0 00-64-110.848V394.88c74.56 26.368 128 97.472 128 181.056v128a192 192 0 01-192 192H448a192 192 0 01-192-192V576a192 192 0 01192-192h192z"
            ></path>
            <path
              fill="#6fe0af"
              d="M384 640v-64h192a128 128 0 00128-128V320a128 128 0 00-128-128H256a128 128 0 00-128 128v128a128 128 0 0064 110.848v70.272A192.064 192.064 0 0164 448V320a192 192 0 01192-192h320a192 192 0 01192 192v128a192 192 0 01-192 192H384z"
            ></path>
          </g>
        </svg>
        <p class="dh-box-text">Nitrado Access Token</p>
        <button @click="openModal('update-token')" class="link-token">
          {{ buttonLabel }}
        </button>
      </div>
    </div>
    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">
            {{
              modalContent == 'link-server'
                ? 'Link New Nitrado Server'
                : 'Update your Nitrado Token'
            }}
          </p>
          <p class="modal-main-heading">
            {{ modalContent == 'link-server' ? 'Servers' : 'Update Token' }}
          </p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body" v-if="modalContent == 'link-server'">
          <div class="modal-row">
            <div class="modal-option">
              <label for="available_servers_to_link" class=""
                >Available Servers To Link</label
              >
              <select
                name=""
                class="dropdown"
                v-model="selectedServer"
                @change="newServerLink.server_id = selectedServer.server_id"
              >
                <option
                  v-for="server in availableServers"
                  :key="server.server_id"
                  :value="server"
                >
                  {{ server.server_name }}
                </option>
              </select>
            </div>

            <div class="modal-option">
              <label for="server_identifier" class="required-input"
                >Server Identifier (public name)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="newServerLink.server_identifier"
                maxlength="100"
              />
            </div>
          </div>
        </div>

        <div class="modal-body" v-if="modalContent == 'update-token'">
          <div class="modal-row">
            <div class="modal-option">
              <label for="server_identifier" class="required-input"
                >New Nitrado Token</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="nitradoToken"
                maxlength="100"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
        <div class="modal-footer-content-container" v-if="modalContent == 'link-server'">
          <button
            @click="linkNewServer()"
            :disabled="
              !availableServers ||
              !newServerLink.server_id ||
              !newServerLink.server_identifier
            "
            class="modal-btn save-btn"
          >
            Add Server
          </button>
        </div>

        <div class="modal-footer-content-container" v-if="modalContent == 'update-token'">
          <button
            @click="submitToken()"
            :disabled="
              !nitradoToken || !updateTokenBtnEnabled
            "
            class="modal-btn save-btn"
          >
            Update Token
          </button>
        </div>
      </template>
    </Modal>

    
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import modal
import Modal from './Modal.vue';

export default {
  name: 'DashboardHome',
  components: {
    Modal,
  },

  data() {
    return {
      showModal: false,
      availableServers: null,
      selectedServer: null,
      serversCountData: 0,
      isTokenLinked: false,
      nitradoToken: '',
      modalContent: 'link-server',
      newServerLink: {
        server_id: null,
        server_identifier: '',
      },
      updateTokenBtnEnabled: true
    };
  },
  watch: {
    '$store.state.client_discord_server': {
      deep: true,
      handler(newVal) {
        if (newVal) {
          if (newVal.servers) {
            this.serversCountData = newVal.servers.length;
          }
          // Update this line to reflect newVal.valid_nitrado_token
          this.isTokenLinked = newVal.valid_nitrado_token;
        }
      },
      immediate: true,
    },
  },
  created() {
    this.fetchClientDashboardData();
  },
  computed: {
    serversCount() {
      return this.serversCountData;
    },
    ...mapState({
      client_discord_server: state => state.client_discord_server,
    }),
    buttonLabel() {
      return this.isTokenLinked ? 'Update Token' : 'Link Token';
    },
  },
  methods: {
    async fetchClientDashboardData() {
      const { lastCacheTimestamp, apiUrl } = this.$store.state;

      // Check if the data is already cached and not expired
      if (lastCacheTimestamp && this.isCacheValid(lastCacheTimestamp) && this.$store.state.client_discord_server) {
        return;
      }

      // Fetch data from the server
      const response = await this.fetchDataFromServer(apiUrl);

      // Handle the response
      if (response) {
        this.$store.commit('SET_CLIENT_DISCORD_SERVER', response);
        this.updateCacheTimestamp();
      } else {
        // Error is handled in the store, no need to return anything
        return;
      }
    },

    isCacheValid(cacheTimestamp) {
      const expirationTime = 15 * 60 * 1000; // 15 minutes in milliseconds
      const currentTimestamp = new Date().getTime();
      return currentTimestamp - cacheTimestamp < expirationTime;
    },

    async fetchDataFromServer(apiUrl) {
      try {
        const response = await this.$store.dispatch('sendRequest', {
          method: 'get',
          url: `${apiUrl}/api/dashboard/home`,
          show_message_on_success: false,
        });
        return response;
      } catch (error) {
        return null;
      }
    },

    updateCacheTimestamp() {
      this.$store.commit('setLastCacheTimestamp', new Date().getTime());
    },

    async getAvailableServersToLink() {
      const response = await this.$store.dispatch('sendRequest', {
        method: 'get',
        url: `${this.$store.state.apiUrl}/api/servers/available`,
        show_message_on_success: false,
      });

      if (response) {
        this.availableServers = response;
        return response
      } else {
        // return because the error is handled in the store
        return;
      }
    },
    async openModal(modalContent) {
      
      if (
        this.$store.state.client_discord_server &&
        this.$store.state.client_discord_server.admin &&
        this.$store.state.client_discord_server.admin.management_role ===
          'owner'
     
      ) {
        if (modalContent === 'link-server') {
          let response = await this.getAvailableServersToLink();
       
          if (!response) return;
          this.modalContent = 'link-server';
        } else {
          this.modalContent = modalContent
        }
      
        this.showModal = true;
      } else {
        this.$store.commit('setStatusMessage', {
          text: 'You do not have permission to do this action.',
          type: 'error',
        });
      }
    },
    async linkNewServer() {
      // check for no spaces and special characters in the server_identifier
      if (!/^[a-zA-Z0-9_]*$/.test(this.newServerLink.server_identifier)) {
        this.$store.commit('setStatusMessage', {
          text: 'Server identifier can only contain letters, numbers, and underscores',
          type: 'error',
        });
        return;
      }
      const response = await this.$store.dispatch('sendRequest', {
        method: 'post',
        url: `${this.$store.state.apiUrl}/api/servers/link`,

        data: {
          nitrado_server_id: this.newServerLink.server_id,
          server_identifier: this.newServerLink.server_identifier,
        },
      });

      if (response) {
        let updateCache = await this.fetchDataFromServer(`${this.$store.state.apiUrl}`);
        if (updateCache) {
          this.$store.commit('SET_CLIENT_DISCORD_SERVER', updateCache);
          this.updateCacheTimestamp();
        }

      }

      this.showModal = false;
      this.newServerLink = {
        server_id: null,
        server_identifier: '',
      };
    },

    async submitToken() {

      this.updateTokenBtnEnabled = false;

      this.$store.commit('setStatusMessage', {
        text: 'Validating token...',
        type: 'success',
      });
    
      // Here you can handle the token submission
      // For example, you can send it to your server
      const response = await this.$store.dispatch('sendRequest', {
        method: 'POST',
        url: `${this.$store.state.apiUrl}/api/dashboard/link-nitrado-token`, // replace with your actual API endpoint,
        extra_headers: {
          'X-Nitrado-Token': this.nitradoToken,
        },
      });

      if (response) {
        this.isTokenLinked = true;
        // update the store with the new token
        this.$store.commit('SET_VALID_NITRADO_TOKEN', true);
        this.showModal = false;
        this.nitradoToken = '';
      }
      this.updateTokenBtnEnabled = true;
      
    },
  },
};
</script>

<style scoped>
.dash-icon {
  width: 24px;
}
.top-statistics {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  align-items: center;
  padding: 20px;
  color: white;
  gap: 40px;
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
}

.dh-home-box {
  background-color: rgba(20, 20, 20, 255);
  flex-direction: column;
  padding: 18px;
  border-radius: 12px;
  display: flex;
  align-items: left;
  text-align: left;
  font-size: 1.6rem;
}

.dh-box-text {
  font-size: 1.4rem;
  color: white;
  font-weight: bold;
  margin-top: 10px;
}

.servers-linked-text {
  font-size: 2.4rem;
  font-weight: bold;
  color: #6fe0af;
}

.flex-text {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-icon {
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
}

.modal-body select option {
  background-color: white; /* White color for the dropdown menu */
  color: black; /* Text color for the options */
  font-size: 1.6rem;
  border-radius: 20px;
}

.link-token {
  background-color: #6fe0af;
  color: white;
  padding: 10px;
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  width: 100px;
  margin-top: 12px;
}
</style>
