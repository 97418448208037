<template>
    <div v-if="show" class="overlay" @click.self="closeModal">
      <div class="modal">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="header-content"></slot>
          </div>
          <div class="modal-body">
            <slot name="body-content"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer-content"></slot>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  export default {
    props: ['show'],
    methods: {
    closeModal() {
      this.$emit('update:show', false);
    },
  },
    // rest of the component...
  };
  </script>
  
  <style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(9, 15, 24, 0.7);
  z-index: 99;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(0deg, #1A222E, #1A222E),
  linear-gradient(0deg, rgba(187, 218, 255, 0.16), rgba(187, 218, 255, 0.16));
  width: 375px;
  border-radius: 16px;
  z-index: 100;
}

  .modal-header {
    padding: 16px;
    border-bottom: 1px solid #BBDAFF1F;
    background: linear-gradient(0deg, #1A222E, #1A222E),
linear-gradient(0deg, rgba(187, 218, 255, 0.16), rgba(187, 218, 255, 0.16));
border-top-right-radius: 16px;
border-top-left-radius: 16px;
    
    
  }

  .modal-body {
    padding: 16px;
    background: linear-gradient(0deg, #1A222E, #1A222E),
linear-gradient(0deg, rgba(187, 218, 255, 0.16), rgba(187, 218, 255, 0.16));
  }

  .modal-footer {
    background: linear-gradient(0deg, #101721, #101721),
linear-gradient(0deg, rgba(187, 218, 255, 0.12), rgba(187, 218, 255, 0.12));
padding: 16px;
border: 1px solid #BBDAFF1F;
border-radius: 0px 0px 16px 16px;
  }
  </style>