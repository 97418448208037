<template>
  <div class="dashboard">
    <!-- import the navbar and sidebar components -->
    <Navbar />
    <Sidebar @change-tab="changeTab" ref="sidebar" />

    <div class="dashboard-content" @click="hideSidebar">
      <component :is="activeTab"></component>
    </div>

    <div class="swipe-me" @click="sidebarMobile">
      <svg width="24px" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 12H18M18 12L13 7M18 12L13 17" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
    </div>

    <StatusMessage />
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import the navbar and sidebar components
import Navbar from '@/components/navbar.vue';
import Sidebar from '@/components/sidebar.vue';
import DashboardHome from '@/components/DashboardHome.vue'; // import the components for the tabs
import CommandsManagement from '@/components/CommandsManagement.vue'; // import the components for the tabs
import GameServersManagement from '@/components/GameServersManagement.vue'; // import the components for the tabs
import StatusMessage from '../components/StatusComponent.vue'
import ShopManagement from '@/components/ShopManagement.vue'; // import the components for the tabs
import ItemAutoBanManagement from '../components/ItemAutoBanManagement.vue';
import GamblingManagement from '../components/GamblingManagement.vue';
import PlacementPingsManagement from '../components/PlacementPingsManagement.vue';

export default {
  name: 'Dashboard',
  components: {
    Navbar,
    Sidebar,
    DashboardHome,
    CommandsManagement,
    GameServersManagement,
    StatusMessage,
    ShopManagement,
    ItemAutoBanManagement,
    GamblingManagement,
    PlacementPingsManagement
  },
  data() {
    return {};
  },
  computed: {
    ...mapState(['user', 'activeTab']),
  },
  methods: {
    changeTab(tabName) {
      this.$store.commit('SET_ACTIVE_TAB', tabName);
      this.hideSidebar();
    },
    sidebarMobile() {
      // select the sidebar and toggle the class
      this.$refs.sidebar.$el.classList.toggle('menu-mobile');
    },
    hideSidebar() {
      this.$refs.sidebar.$el.classList.remove('menu-mobile');
    },
  },
  created() {
    if (!this.user) {
      this.$router.push('Home');
    }
    window.addEventListener('click', (event) => {
      if (!this.$refs.sidebar.$el.contains(event.target) && this.$refs.sidebar.$el.classList.contains('menu-mobile')) {
        this.hideSidebar();
      }
    });
  },
  created() {
    if (!this.user) {
      this.$router.push('Home');
    }
  },
};
</script>

<style scoped>
.dashboard {
  display: grid;
  grid-template-columns: auto 1fr; /* auto for the sidebar, rest for the content */
  grid-template-rows: auto 1fr; /* auto for the navbar, rest for the content */
  grid-template-areas:
    'navbar navbar'
    'sidebar content';
    position: relative;
  height: 100vh;
}

.navbar {
  grid-area: navbar;
}

.sidebar {
  grid-area: sidebar;
}

.dashboard-content {
  grid-area: content;
  background: #090f18;
  padding-right: 24px;
}

.swipe-me {
  display: none;
}

@media (max-width: 800px) {
  .dashboard {
    grid-template-columns: 1fr; /* only one column */
    grid-template-rows: auto 1fr; /* navbar and content */
    grid-template-areas:
      'navbar'
      'content';
  }

  .dashboard-content {
    padding: 24px;
  }

  .menu {
    display: none; /* hide the sidebar */
  }

  /* make it far left of the screen at the centre */
  .swipe-me {
    display: block;
    position: fixed;
    bottom: 50vh;
    left: 0%;
    color: white;
    padding: 12px 12px;
  }
}

.menu-mobile {
  display: block;
  position: fixed;
  z-index: 100;
}
</style>
