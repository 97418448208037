import axios from 'axios';
import { createStore } from 'vuex';
import router from '../router/index';

const initialState = {
  apiUrl:
    process.env.VUE_APP_ENVIRONMENT === 'development'
      ? 'http://192.168.1.242:5000'
      : process.env.VUE_APP_API_URL,
  application_id:
    process.env.VUE_APP_APPLICATION_ID === 'development'
      ? '1161237266207875102'
      : process.env.VUE_APP_APPLICATION_ID,
  discordRedirectUrl: process.env.VUE_APP_ENVIRONMENT === 'development' ? `https://discord.com/oauth2/authorize?client_id=1161237266207875102&response_type=code&redirect_uri=http%3A%2F%2F192.168.1.242%3A8080&scope=identify+applications.commands.permissions.update+guilds` : `https://discord.com/oauth2/authorize?client_id=${process.env.VUE_APP_APPLICATION_ID}&response_type=code&redirect_uri=https%3A%2F%2Fdazza-dayz-bot.co.uk&scope=identify+guilds+applications.commands.permissions.update`,
  isAuthenticated: false,
  user: null,
  selected_discord_server_id: null,
  client_discord_server: null,
  discord_access_token: null,
  discord_refresh_token: null,
  activeTab: null,
  statusMessage: null,
  shopStatistics: null,
  lastCacheTimestamp: null,
  bannedItems: {
    banned_items: [],
    last_cache_timestamp: null,
  },
  gamblingConfigs: {
    configs: {},
    last_cache_timestamp: null,
  },
  placementPings: {
    placement_pings: [],
    last_cache_timestamp: null,
  }
};

export default createStore({
  state: {
    apiUrl:
      process.env.VUE_APP_ENVIRONMENT === 'development'
        ? 'http://192.168.1.242:5000'
        : process.env.VUE_APP_API_URL,
    application_id:
      process.env.VUE_APP_APPLICATION_ID === 'development'
        ? '1161237266207875102'
        : process.env.VUE_APP_APPLICATION_ID,

    discordRedirectUrl: process.env.VUE_APP_ENVIRONMENT === 'development' ? `https://discord.com/oauth2/authorize?client_id=1161237266207875102&response_type=code&redirect_uri=http%3A%2F%2F192.168.1.242%3A8080&scope=identify+applications.commands.permissions.update+guilds` : `https://discord.com/oauth2/authorize?client_id=${process.env.VUE_APP_APPLICATION_ID}&response_type=code&redirect_uri=https%3A%2F%2Fdazza-dayz-bot.co.uk&scope=identify+guilds+applications.commands.permissions.update`,
    isAuthenticated: false,
    user: null,
    selected_discord_server_id: null,
    client_discord_server: null,
    discord_access_token: null,
    discord_refresh_token: null,
    activeTab: null,
    statusMessage: null,
    shopStatistics: null,
    lastCacheTimestamp: null,
    bannedItems: {
      banned_items: [],
      last_cache_timestamp: null,
    },

    gamblingConfigs: {
      configs: {},
      last_cache_timestamp: null,
    },
    placementPings: {
      placement_pings: [],
      last_cache_timestamp: null,
    }
  },
  mutations: {

    setGamblingConfigs(state, payload) {
      state.gamblingConfigs = payload
      localStorage.setItem('gamblingConfigs', JSON.stringify(payload));
    },

    setPlacementPings(state, payload) {
      state.placementPings = payload;
      localStorage.setItem('placementPings', JSON.stringify(payload));
    },

    setStatusMessage(state, message) {
      state.statusMessage = message;
    },

    setBannedItems(state, payload) {
      state.bannedItems = payload;
      localStorage.setItem('bannedItems', JSON.stringify(payload));
    },

    setLastCacheTimestamp(state, timestamp) {
      state.lastCacheTimestamp = timestamp;
      localStorage.setItem('lastCacheTimestamp', timestamp);
    },

    SET_SHOP_STATISTICS(state, payload) {
      state.shopStatistics = payload;
      localStorage.setItem('shopStatistics', JSON.stringify(payload));
    },

    UPDATE_SERVER_VALUES(state, payload) {
      let index = state.client_discord_server.servers.findIndex(
        x => x.id === payload.server_id
      );

      state.client_discord_server.servers[index] = payload.value;

      localStorage.setItem(
        'client_discord_server',
        JSON.stringify(state.client_discord_server)
      );
    },
    SET_VALID_NITRADO_TOKEN(state, value) {
      state.client_discord_server.valid_nitrado_token = value;
      localStorage.setItem(
        'client_discord_server',
        JSON.stringify(state.client_discord_server)
      );
    },
    SET_AUTHENTICATED(state, value) {
      state.isAuthenticated = value;
      localStorage.setItem('isAuthenticated', value);
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user', JSON.stringify(user));
    },
    SET_SELECTED_DISCORD_SERVER_ID(state, value) {
      state.selected_discord_server_id = value;
      localStorage.setItem('selected_discord_server_id', JSON.stringify(value));
    },

    SET_CLIENT_DISCORD_SERVER(state, value) {
      state.client_discord_server = value;
      localStorage.setItem('client_discord_server', JSON.stringify(value));
    },
    SET_DISCORD_ACCESS_TOKEN(state, value) {
      state.discord_access_token = value;
      localStorage.setItem('discord_access_token', JSON.stringify(value));
    },
    SET_DISCORD_REFRESH_TOKEN(state, value) {
      state.discord_refresh_token = value;
      localStorage.setItem('discord_refresh_token', JSON.stringify(value));
    },
    SET_ACTIVE_TAB(state, value) {
      state.activeTab = value;
      localStorage.setItem('activeTab', JSON.stringify(value));
    },

    UPDATE_SHOP_OPEN(state, value) {
      state.shopStatistics.shop_open = value;
      localStorage.setItem('shopStatistics', JSON.stringify(state.shopStatistics));
    },

    RESET_STATE(state) {
      Object.assign(state, initialState);
  },
},
  actions: {
    login({ commit }, user, discord_access_token, discord_refresh_token) {
      // Perform login logic here
      commit('SET_AUTHENTICATED', true);
      commit('SET_USER', user);
      commit('SET_DISCORD_ACCESS_TOKEN', discord_access_token);
      commit('SET_DISCORD_REFRESH_TOKEN', discord_refresh_token);
    },
    resetStatusMessage({ commit }) {
      commit('setStatusMessage', null);
    },
    logout({ commit}) {
      // Perform logout logic here
      commit('SET_AUTHENTICATED', false);
      commit('SET_USER', null);
      commit('SET_SELECTED_DISCORD_SERVER_ID', null);
      commit('SET_CLIENT_DISCORD_SERVER', null);
      commit('SET_DISCORD_ACCESS_TOKEN', null);
      commit('SET_DISCORD_REFRESH_TOKEN', null);
      // set the state back to the initial state
      commit('RESET_STATE');
      localStorage.clear();


      // loop through the keys in the

      // send request to logout
      axios
        .post(`${this.state.apiUrl}/api/dashboard/logout`)
        .then(response => {})
        .catch(error => {});

      // send to /login route
      router.push({ name: 'Home' });
    },

    async ManageServer({ commit, dispatch }, selected_discord_server_id) {
      try {
        const response = await dispatch('sendRequest', {
          url: `${this.state.apiUrl}/api/dashboard/verify-client`,
          method: 'POST',
          data: { discord_server_id: selected_discord_server_id },
        });
        commit('SET_SELECTED_DISCORD_SERVER_ID', selected_discord_server_id);
        localStorage.setItem(
          'selected_discord_server_id',
          JSON.stringify(selected_discord_server_id)
        );
        return response;
      } catch (error) {
        // Perform some operation here if an error occurs in sendRequest

        return error;
      }
    },

    async sendRequest(
      { state, dispatch, commit },
      {
        url,
        method,
        data,
        params,
        extra_headers,
        show_message_on_success = true,
      }
    ) {
      const headers = {
        'Content-Type': 'application/json',
        'X-Client-Server-ID': state.selected_discord_server_id,
        Authorization: 'Bearer ' + process.env.VUE_APP_API_ACCESS_TOKEN,
        ...extra_headers,
        // Add your specific headers here
      };

      const body = {
        // Add your specific body structure here
        ...data,
      };

      const requestParams = {
        // Add your specific params here
        ...params,
      };

      try {
        const response = await axios({
          url,
          method,
          headers,
          data: body,
          params: requestParams,
        });

        if (show_message_on_success) {
          commit('setStatusMessage', {
            text: response.data.data.message,
            type: 'success',
          });
        }
        return response.data.data;
      } catch (error) {
       
        // Handle the error here
        if (error.response && error.response.status === 401) {
          // update the status message
          commit('setStatusMessage', {
            text: 'Your session has expired. Please log in again.',
            type: 'error',
          });
          dispatch('logout');
        } else if (error.response && error.response.data) {
          commit('setStatusMessage', {
            text: error.response.data.reason,
            type: 'error',
          });
        } else {
          commit('setStatusMessage', {
            message: 'An Unexpected error occurred. Please try again.',
            type: 'error',
          });
        }
        // Don't return or rethrow the error
      }
    },

    async LoadDashboardHome({ commit, dispatch }) {
      // Define your url, method, data, and params here
      const url = `${this.state.apiUrl}/api/dashboard/home`;
      const method = 'GET';
      const data = {}; // Your data
      const params = {}; // Your params
      let show_message_on_success = false;

      dispatch('sendRequest', {
        url,
        method,
        data,
        params,
        show_message_on_success,
      })
        .then(response => {
          // update the client_discord_server
          commit('SET_CLIENT_DISCORD_SERVER', response);
        })
        .catch(error => {
          // Handle the error here
         
        });
    },
  },

  getters: {
    isAuthenticated: state => state.isAuthenticated,
    user: state => state.user,
  },

  plugins: [
    // Load the state from localStorage when the store is initialized
    store => {
      if (
        localStorage.getItem('isAuthenticated') &&
        localStorage.getItem('user')
      ) {
        store.commit(
          'SET_AUTHENTICATED',
          JSON.parse(localStorage.getItem('isAuthenticated'))
        );
        store.commit('SET_USER', JSON.parse(localStorage.getItem('user')));
        store.commit(
          'SET_SELECTED_DISCORD_SERVER_ID',
          JSON.parse(localStorage.getItem('selected_discord_server_id'))
        );
        store.commit(
          'SET_CLIENT_DISCORD_SERVER',
          JSON.parse(localStorage.getItem('client_discord_server'))
        );
        store.commit(
          'SET_DISCORD_ACCESS_TOKEN',
          JSON.parse(localStorage.getItem('discord_access_token'))
        );
        store.commit(
          'SET_DISCORD_REFRESH_TOKEN',
          JSON.parse(localStorage.getItem('discord_refresh_token'))
        );
        store.commit(
          'SET_ACTIVE_TAB',
          JSON.parse(localStorage.getItem('activeTab'))
        );
      }

      if (localStorage.getItem('shopStatistics')) {
        store.commit(
          'SET_SHOP_STATISTICS',
          JSON.parse(localStorage.getItem('shopStatistics'))
        );
      }

      if (localStorage.getItem('lastCacheTimestamp')) {
        store.commit(
          'setLastCacheTimestamp',
          localStorage.getItem('lastCacheTimestamp')
        );

      }

      if (localStorage.getItem('bannedItems')) {
        store.commit(
          'setBannedItems',
          JSON.parse(localStorage.getItem('bannedItems'))
        );
      }

      if (localStorage.getItem('gamblingConfigs')) {
        store.commit(
          'setGamblingConfigs',
          JSON.parse(localStorage.getItem('gamblingConfigs'))
        );
      }

      if (localStorage.getItem('placementPings')) {
        store.commit(
          'setPlacementPings',
          JSON.parse(localStorage.getItem('placementPings'))
        );
    }
  }
  ],

  modules: {},
});
