import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Dashboard from "../views/Dashboard.vue";
import store from '../store'; // import your Vuex store

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true }, // add this line
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Add a beforeEach navigation guard
router.beforeEach((to, from, next) => {
  // Check if the route requires authentication
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Check if the user is not authenticated
    if (!store.state.isAuthenticated || !store.state.user || !store.state.selected_discord_server_id) {
      next({ name: 'Home' }); // redirect to home
    } else {
      next(); // proceed to route
    }
  } else {
    next(); // proceed to route
  }
});

export default router;