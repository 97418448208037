<template>
  <div class="game-servers-management-container">
    <div class="game-servers-options-container">
      <h1 class="gsoc-heading content-header">Game Server Options</h1>
    </div>

    <select
      name=""
      id=""
      class="choose-gameserver-dropdown"
      v-model="SelectedGameServer"
      @change="updateSelectedGameServer"
    >
      <option disabled value="">Choose Game Server</option>
      <option v-for="server in GameServers" :key="server" :value="server">
        {{ server.server_identifier }}
      </option>
    </select>

    <p class="server-options-sub-heading">Server Options</p>

    <div class="gsoc-content">
      <button
        class="gsoc-button"
        @click="openModal('general')"
        :disabled="!SelectedGameServer"
      >
        General settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('bounty')"
        :disabled="!SelectedGameServer"
      >
        Bounty settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('spawn_kill')"
        :disabled="!SelectedGameServer"
      >
        Spawn Kill settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('killfeed')"
        :disabled="!SelectedGameServer"
      >
        Killfeed settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('webhooks')"
        :disabled="!SelectedGameServer"
      >
        Webhook settings
      </button>
      <button
        class="gsoc-button"
        @click="openModal('Scheduled tasks')"
        :disabled="!SelectedGameServer"
      >
        Scheduled Task Settings
      </button>
    </div>
    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">{{ modalHeader }}</p>
          <p class="modal-main-heading">Edit Configuration</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <form v-if="modalContent === 'general'" class="modal-body-cc-inner">
            <div class="config-row">
              <div class="config-option">
                <label for="server_identifier">Server Identifier</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="updatedSelectedGameServer.server_identifier"
                  type="text"
                  maxlength="50"
                  required
                />
              </div>
              <div class="config-option">
                <label for="role_id_on_link">Role On Account Link</label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="updatedSelectedGameServer.link_role_id"
                >
                  <option value="null" disabled selected>No Role</option>
                  <option
                    v-for="role in allRoles"
                    :key="role.name"
                    :value="Number(role.id)"
                  >
                    {{ role.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="server_identifier">Credits Per Minute Played</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="updatedSelectedGameServer.credits_per_minute_played"
                  type="number"
                  max="1000000"
                  min="0"
                />
              </div>
              <div class="config-option">
                <label for="server_restart_interval"
                  >Server Restart Interval (hours)</label
                >
                <input
                  name=""
                  class="config-option-input"
                  v-model="updatedSelectedGameServer.server_restart_interval"
                  type="number"
                  max="6"
                  min="1"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="server_restart_time"
                  >Server Restart Time (Local)</label
                >
                <input
                  name=""
                  class="config-option-input"
                  v-model="formattedDateTimeServerRestart"
                  type="datetime-local"
                  :min="currentDateTimeLocal"
                />
              </div>
              <div class="config-option">
                <label for="max_account_link">Max Account Link</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="updatedSelectedGameServer.max_account_link"
                  type="number"
                  min="1"
                  max="15"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="apply_credits_on_build"
                  >Apply Credits on Build</label
                >
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="updatedSelectedGameServer.apply_credits_on_build"
                >
                  <option
                    value="true"
                    :selected="
                      (updateSelectedGameServer.apply_credits_on_build = true)
                    "
                  >
                    True
                  </option>
                  <option
                    value="false"
                    :selected="
                      (updateSelectedGameServer.apply_credits_on_build = false)
                    "
                  >
                    False
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="credits_on_build">Credits On Build</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="updatedSelectedGameServer.credits_on_build"
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="server_active_connections_channel_id">
                  Connections Display Channel
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="updatedSelectedGameServer.server_stats_channel_id"
                >
                <option value="">None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>

                </select>

              </div>
              <div class="config-option">
                <label for="allow_shop_purchases">
                  Allow Shop Purchases
                </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="updatedSelectedGameServer.allow_shop_purchases"
                >
                  <option value="true">True</option>
                  <option value="false">False</option>

                </select>

              </div>
            </div>
          </form>

          <form class="modal-body-cc-inner" v-if="modalContent === 'bounty'">
            <div class="config-row">
              <div class="config-option">
                <label for="allow_bounties">Allow Bounties</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    updatedSelectedGameServer.bounty_config.allow_bounties
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="minimum_bounty_amount">Minimum Bounty Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.bounty_config
                      .minimum_bounty_amount
                  "
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="maximum_bounty_amount">Maximum Bounty Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.bounty_config
                      .maximum_bounty_amount
                  "
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
              <div class="config-option">
                <label for="maximum_bounty_duration_minutes"
                  >Max Bounty Time (Minutes)
                </label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.bounty_config
                      .maximum_bounty_duration_minutes
                  "
                  type="number"
                  min="0"
                  max="525600"
                />
              </div>
            </div>
          </form>
          <form
            class="modal-body-cc-inner"
            v-if="modalContent === 'spawn_kill'"
          >
            <div class="config-row">
              <div class="config-option">
                <label for="remove_credits_on_spawn_kill">Remove Credits</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config
                      .spawn_kill_deduction
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="minimum_bounty_amount"
                  >Spawn Kill Timer (seconds)</label
                >
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config
                      .spawn_kill_seconds
                  "
                  type="number"
                  min="10"
                  max="600"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="remove_credits_amount">Remove Credits Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config
                      .spawn_kill_amount
                  "
                  type="number"
                  min="1"
                  max="1000000"
                />
              </div>
              <div class="config-option">
                <label for="credit_removal_type">Credit Removal Type </label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config.spawn_kill_type
                  "
                >
                  <option value="percentage">Percentage</option>
                  <option value="value">Value</option>
                </select>
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="ban_on_spawn_kill">Ban On Spawn Kill</label>
                <select
                  name=""
                  class="config-option-dropdown"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config.spawn_kill_ban
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="spawn_kill_ban_duration">Ban duration (days)</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config
                      .spawn_kill_ban_duration
                  "
                  type="number"
                  min="1"
                  max="365"
                />
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="spawn_kill_bail_amount">Ban Bail Amount</label>
                <input
                  name=""
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.spawn_kill_config.spawn_kill_bail
                  "
                  type="number"
                  min="0"
                  max="1000000"
                />
              </div>
            </div>
          </form>

          <form
            action=""
            v-if="modalContent === 'killfeed'"
            class="modal-body-cc-inner"
          >
            <div class="config-row">
              <div class="config-option">
                <label for="apply_credits_on_kill">Apply Credits On Kill</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    updatedSelectedGameServer.killfeed_config.credits_on_kill
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
              <div class="config-option">
                <label for="credit_amount_on_kill">Credits On Kill</label>
                <input
                  type="number"
                  min="1"
                  max="1000000"
                  class="config-option-input"
                  v-model="
                    updatedSelectedGameServer.killfeed_config.kill_credits
                  "
                />
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="show_killer_location">Show Killer Location</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    updatedSelectedGameServer.killfeed_config
                      .show_killer_location
                  "
                >
                  <option value="true">True</option>
                  <option value="false">False</option>
                </select>
              </div>
            </div>
          </form>

          <form
            action=""
            class="modal-body-cc-inner"
            v-if="modalContent === 'webhooks'"
          >
            <div class="config-row">
              <div class="config-option">
                <label for="killfeed_webhook_channel"
                  >Killfeed Webhook Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.killfeed_webhook_channel_id"
                >
                  <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="connections_webhook_url"
                  >Connections Webhook Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.connection_logs_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="leaderboards_webhook_channel"
                  >Leaderboards Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.leaderboards_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="placements_webhook_channel"
                  >Placement Logs Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.placement_logs_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="bounties_webhook_url"
                  >Bounties Webhook Channel</label
                >

                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.bounties_webhook_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="pvp_zones_webhook_channel">PvP Zones Channel</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.pvp_zones_channel_id"
                >
                 <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="config-row">
              <div class="config-option">
                <label for="active_faction_wars_webhook_url"
                  >Active Faction Wars Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="updatedWebhooksConfig.faction_wars_active_channel_id"
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="faction_wars_history_webhook_channel"
                  >Faction Wars History Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="
                    updatedWebhooksConfig.faction_wars_history_channel_id
                  "
                >
                <option value="" selected>None</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
            </div>
          </form>

          <form
            v-if="modalContent === 'bounty'"
            class="modal-body-cc-inner"
          ></form>

          <form
            v-if="modalContent === 'Scheduled tasks'"
            class="modal-body-cc-inner"
          >
            <div class="config-option task-type-option">
              <label for="task-type-dropdown">Scheduled Task</label>
              <select
                name=""
                id=""
                class="config-option-dropdown"
                v-model="selectedScheduledTask"
                @change="cloneSelectedTaskSchedule"
              >
                <option value="heatmap_task_schedule">Heatmap</option>
                <option value="in_depth_logs_task_schedule">
                  In-Depth Logs
                </option>
              </select>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="task-interval" class="label required-input"
                  >Task Interval (minutes)</label
                >
                <input
                  name=""
                  class="config-option-input"
                  type="number"
                  v-model="selectedScheduledTaskConfig.time_interval"
                  min="1"
                  max="100000"
                />
              </div>
              <div class="config-option">
                <label for="task-time">Task Start Time (Local)</label>
                <input
                  name=""
                  class="config-option-input"
                  type="datetime-local"
                  :min="currentDateTimeLocal"
                  v-model="formattedDateTimeLocalTaskConfig"
                />
              </div>
            </div>

            <div class="config-row">
              <div class="config-option">
                <label for="task_channel_id" class="label required-input"
                  >Task Webhook Channel</label
                >
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="selectedScheduledTaskConfig.channel_id"
                >
                  <option value="" disabled selected>Not Set</option>
                  <option
                    v-for="channel in allChannels"
                    :key="channel.id"
                    :value="channel.id"
                  >
                    {{ channel.name }}
                  </option>
                </select>
              </div>
              <div class="config-option">
                <label for="task_channel_id" class="label">Task Enabled</label>
                <select
                  name=""
                  id=""
                  class="config-option-dropdown"
                  v-model="selectedScheduledTaskConfig.enabled"
                >
                  <option value="false">False</option>
                  <option value="true">True</option>
                </select>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-slot:footer-content>
        

        <div
          class="modal-footer-content-container"
          v-if="modalContent !== 'Scheduled tasks'"
        >
          <button
            class="modal-btn cancel-btn"
            @click="discardChanges('general')"
          >
            Cancel Changes
          </button>
          <button
            :disabled="!allowSubmitGeneralChanges"
            class="modal-btn save-btn"
            @click="submitServerChanges(modalContent)"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>

        <div
          class="modal-footer-content-container"
          v-if="modalContent === 'Scheduled tasks'"
        >
          <button
            class="modal-btn cancel-btn"
            @click="this.selectedScheduledTaskConfig = {}"
          >
            Cancel Changes
          </button>
          <button
            :disabled="
              !selectedScheduledTaskConfig.time_interval ||
              !selectedScheduledTaskConfig.channel_id
            "
            class="modal-btn save-btn"
            @click="submitScheduledTaskChanges()"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save</p>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';
import Modal from './Modal.vue';
export default {
  components: {
    Modal,
  },
  data() {
    return {
      showModal: false,
      modalContent: null,
      SelectedGameServer: '',
      modalHeader: '',
      rolesAndChannels: [],
      updatedSelectedGameServer: {},
      allowSubmitGeneralChanges: true,
      guildWebhooks: [],
      updatedWebhooksConfig: {
        faction_wars_active_channel_id: '',
        faction_wars_history_channel_id: '',
        killfeed_webhook_channel_id: '',
        pvp_zones_channel_id: '',
        bans_webhook_channel_id: '',
        connection_logs_channel_id: '',
        bounties_webhook_channel_id: '',
        placement_logs_channel_id: '',
        leaderboards_channel_id: '',
      
      },
      selectedScheduledTask: '',
      selectedScheduledTaskConfig: {},
      initialWebhooksConfig: {},
    };
  },

  computed: {
    ...mapState({
      GameServers: state =>
        state.client_discord_server ? state.client_discord_server.servers : [],
    }),
    currentDateTime() {
      const now = new Date();
      const year = now.getUTCFullYear();
      const month = String(now.getUTCMonth() + 1).padStart(2, '0');
      const day = String(now.getUTCDate()).padStart(2, '0');
      const hours = String(now.getUTCHours()).padStart(2, '0');
      const minutes = String(now.getUTCMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    currentDateTimeLocal() {
      const now = new Date();
      const year = now.getFullYear();
      const month = String(now.getMonth() + 1).padStart(2, '0');
      const day = String(now.getDate()).padStart(2, '0');
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      return `${year}-${month}-${day}T${hours}:${minutes}`;
    },

    formattedDateTimeServerRestart: {
      get() {
        if (!this.updatedSelectedGameServer.server_restart_time) {
          return '';
        }
        return this.convertUtcToLocal(
          this.updatedSelectedGameServer.server_restart_time
        );
      },
      set(value) {
        if (value === '') {
          // Handle empty string input
          this.updatedSelectedGameServer.server_restart_time = null; // Or handle accordingly
        } else {
          this.updatedSelectedGameServer.server_restart_time = this.convertLocalToUtc(
            value
          );
        }
      },
    },

    formattedDateTimeLocalTaskConfig: {
      get() {
        if (
          !this.selectedScheduledTaskConfig ||
          !this.selectedScheduledTaskConfig.next_task_run
        ) {
          return '';
        }
        return this.convertUtcToLocal(
          this.selectedScheduledTaskConfig.next_task_run
        );
      },
      set(value) {
        if (value === '') {
          // Handle empty string input
          this.selectedScheduledTaskConfig.next_task_run = null; // Or handle accordingly
        } else {
          // convert the local time to utc
          this.selectedScheduledTaskConfig.next_task_run =
            this.convertLocalToUtc(value);
        }
      },
    },

    formattedDateTime: {
      get() {
        return this.updatedSelectedGameServer.server_restart_time
          ? this.updatedSelectedGameServer.server_restart_time.slice(0, -3)
          : '';
      },
      set(value) {
        this.updatedSelectedGameServer.server_restart_time = `${value}:00`;
      },
    },

    bountySettings() {
      const serverDetails = this.updatedSelectedGameServer;
      return Object.keys(serverDetails, bounty_config);
    },
    allRoles() {
      return this.rolesAndChannels.filter(permType => {
        return permType.type === 'role';
      });
    },
    allChannels() {
      return this.rolesAndChannels.filter(permType => {
        return permType.type === 'channel';
      });
    },
  },

  methods: {
    async submitScheduledTaskChanges() {
      // disable the button
      this.allowSubmitGeneralChanges = false;

      // Get the changed config key
      let changedConfigJsonKey = this.selectedScheduledTask.replace(
        '_task_schedule',
        ''
      );
      changedConfigJsonKey = changedConfigJsonKey.toString();

      let changedConfig = this.selectedScheduledTaskConfig;

      // Only get the updated values
      let updatedConfig = {
        server_id: this.SelectedGameServer.id,
      };
      if (!updatedConfig[changedConfigJsonKey]) {
        updatedConfig[changedConfigJsonKey] = {};
      }

      for (let key in changedConfig) {
        if (
          changedConfig[key] !==
          this.updatedSelectedGameServer[this.selectedScheduledTask][key]
        ) {
          if (key === 'next_task_run') {
            console.log(updatedConfig, changedConfigJsonKey, key, changedConfig)
            updatedConfig[changedConfigJsonKey][key] = changedConfig[key].replace('.000Z', '');
             // Parse the UTC datetime string
            const utcDate = new Date(changedConfig[key]).toUTCString();
            // Check if the next_task_run is in the past
            if (
              utcDate < new Date()
            ) {
              this.$store.commit('setStatusMessage', {
                type: 'error',
                text: 'The next task run time cannot be in the past. Please select a future time.',
              });
              return;
            }
          } else {
            // Use changedConfigJsonKey as the key for updatedConfig

            updatedConfig[changedConfigJsonKey][key] = changedConfig[key];
          }
        }
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers/server-tasks-schedule`,
        data: updatedConfig,
      });
      this.SelectedGameServer[this.selectedScheduledTask] = response.new_settings
      // update the selected server with the new config
      this.$store.commit('UPDATE_SERVER_VALUES', {
        server_id: updatedConfig.server_id,
        value: this.SelectedGameServer,
      });

      this.showModal = false;
    },

    convertUtcToLocal(datetime_string) {
      // if the string contains this :00.000Z, it means it's already in local time, and orginates from the fact that the task run time was initially set to null
      if (datetime_string.includes(':00.000Z')) {
        return datetime_string.slice(0, 16);
      }

      // Parse the UTC datetime string
      let date = new Date(Date.parse(datetime_string + 'Z')); // Append 'Z' to indicate UTC timezone

      // Extract date components
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2); // Add 1 to month because it's zero-based
      let day = ('0' + date.getDate()).slice(-2);
      let hours = ('0' + date.getHours()).slice(-2);
      let minutes = ('0' + date.getMinutes()).slice(-2);

      // Construct the formatted date string
      let formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

      // Assign the formatted date to the v-model
      return formattedDate;

    
    },

    convertLocalToUtc(local_datetime_string) {
      const localDate = new Date(local_datetime_string);
    
    // Extract date components
    const year = localDate.getUTCFullYear();
    const month = ('0' + (localDate.getUTCMonth() + 1)).slice(-2); // Add 1 to month because it's zero-based
    const day = ('0' + localDate.getUTCDate()).slice(-2);
    const hours = ('0' + localDate.getUTCHours()).slice(-2);
    const minutes = ('0' + localDate.getUTCMinutes()).slice(-2);
    
    // Construct the formatted UTC date string
    const utcDateString = `${year}-${month}-${day}T${hours}:${minutes}`;
    
    return utcDateString;
    },

    cloneSelectedTaskSchedule() {
  
      // get the current selected server
      const selectedServer = this.updatedSelectedGameServer;
      // get the selected task
      const selectedTask = this.selectedScheduledTask;

      // clone the selected task schedule
      this.selectedScheduledTaskConfig = JSON.parse(
        JSON.stringify(selectedServer[selectedTask])
      );
      
    },

    updateWebhooksConfig() {
      for (let key in this.updatedSelectedGameServer.webhooks_config) {
        let webhook = this.guildWebhooks.find(
          webhook =>
            webhook.url === this.updatedSelectedGameServer.webhooks_config[key]
        );
        if (webhook) {
          this.updatedWebhooksConfig[key.replace('_url', '_channel_id')] =
            webhook.channel_id;
        }
      }

      this.initialWebhooksConfig = JSON.parse(
        JSON.stringify(this.updatedWebhooksConfig)
      );
    },

    discardChanges(type) {
      this.updatedSelectedGameServer = JSON.parse(
        JSON.stringify(this.SelectedGameServer)
      );

      this.updatedWebhooksConfig = JSON.parse(
        JSON.stringify(this.initialWebhooksConfig)
      );

      this.selectedScheduledTaskConfig = {};
  

      this.showModal = false;
    },

    async getGuildWebhooks() {
      const response = await axios.get(
        `${this.$store.state.apiUrl}/api/dashboard/discord/guilds/webhooks`,
        {
          params: {
            guild_id: this.$store.state.client_discord_server.discord_server_id,
          },
        }
      );

      return response;
    },

    async submitServerChanges(type) {

      if (type == 'general') {
        await this.submitGeneralServerDetails();
      }

      if (type == 'bounty') {
        await this.submitBountyUpdatedDetails()
      }

      if (type == 'spawn_kill') {
        await this.submitSpawnKillUpdatedDetails()
      }

      if (type == 'killfeed') {
        await this.submitKillfeedConfigUpdatedDetails()
      }

      if (type == 'webhooks') {
        await this.submitWebhookConfigUpdatedDetails()
      }

    
    },

    updateSelectedGameServer() {
      this.updatedSelectedGameServer = JSON.parse(
        JSON.stringify(this.SelectedGameServer)
      );
    },

    openModal(contentType) {
      let isResponseGood = true;

      if (contentType === 'webhooks') {
        try {
          // check if user has perms
          const response = this.getGuildWebhooks();
          response
            .then(response => {
              this.guildWebhooks = response.data.data;
              this.updateWebhooksConfig();
            })
            .catch(error => {
              isResponseGood = false;
            });
        } catch (error) {
          isResponseGood = false;
        }
      }

      if (isResponseGood) {
        this.showModal = true;
        this.modalHeader =
          `${contentType} settings`.charAt(0).toUpperCase() +
          `${contentType} settings`.slice(1);

        this.modalContent = contentType;
      }
    },
    closeModal() {
      this.showModal = false;
      this.modalContent = null;
    },
    async getGuildChannelsAndRoles() {
      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/dashboard/discord/guilds/roles-and-channels`,
        params: {
            guild_id: this.$store.state.client_discord_server.discord_server_id,
          },
        show_message_on_success: false
        }
      );

      this.rolesAndChannels = [
  ...response.roles.map(role => ({
    ...role,
    type: 'role',
    selected: false,
  })),
  ...response.channels
  .filter(channel => channel.parent_id !== null)
  .map(channel => ({
    ...channel,
    type: 'channel',
    selected: false,
  })),
];
    },

    async submitGeneralServerDetails() {
      // disable the button
      this.allowSubmitGeneralChanges = false;
      // Only get the updated values
      let updatedConfig = {
        server_id: this.SelectedGameServer.id,
      };
      let changedConfig = this.updatedSelectedGameServer;

      const forbiddenkeys = ['killfeed_config', 'webhooks_config', 'faction_wars_config', 'heatmap_task_schedule', 'in_depth_logs_task_schedule', 'spawn_kill_config', 'bounty_config'];
      

      for (let key in changedConfig) {
        if (
          changedConfig[key] !==
          this.SelectedGameServer[key] &&
          !forbiddenkeys.includes(key)
        ) {
          if (key === 'server_restart_time') {
           
            // let utcString = this.convertLocalToUtc(changedConfig[key]);
            changedConfig[key] = changedConfig[key].replace('.000Z', '');

         
            // Parse the UTC datetime string
            const utcDate = new Date(changedConfig[key]).toUTCString();
          

            // Check if scheduled time is in the past
            if (utcDate < new Date()) {
                this.$store.commit('setStatusMessage', {
                    type: 'error',
                    text: 'Please select a future time for the server restart.',
                });
                this.allowSubmitGeneralChanges = true;
                return;
            }

          } 
          updatedConfig[key] = changedConfig[key];
        }
      }
      

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers`,
        data: updatedConfig,
      });

     
      this.$store.commit('UPDATE_SERVER_VALUES', {
        server_id: updatedConfig.server_id,
        value: response.server,
      })
      this.SelectedGameServer = response.server;
      this.updatedSelectedGameServer = {...response.server};
      this.allowSubmitGeneralChanges = true;
      this.showModal = false;


    },

    async submitBountyUpdatedDetails() {
      // disable the button
      this.allowSubmitGeneralChanges = false;

      let updatedConfig = {
        server_id: this.SelectedGameServer.id,
        bounty_config: {},
      };
      let changedConfig = this.updatedSelectedGameServer.bounty_config;

      for (let key in changedConfig) {
        if (
          changedConfig[key] !==
          this.SelectedGameServer.bounty_config[key]
        ) {
          updatedConfig.bounty_config[key] = changedConfig[key];
        }
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers`,
        data: updatedConfig,
      });


      this.$store.commit('UPDATE_SERVER_VALUES', {
        server_id: updatedConfig.server_id,
        value: response.server,
      });

      this.SelectedGameServer = response.server;
      this.updatedSelectedGameServer = JSON.parse(
        JSON.stringify(this.SelectedGameServer)
      );
      this.allowSubmitGeneralChanges = true;
      this.showModal = false;
    },

    async submitSpawnKillUpdatedDetails() {
      // disable the button
      this.allowSubmitGeneralChanges = false;

      let updatedConfig = {
        server_id: this.SelectedGameServer.id,
        spawn_kill_config: {},
      };
      let changedConfig = this.updatedSelectedGameServer.spawn_kill_config;

      for (let key in changedConfig) {
        if (
          changedConfig[key] !==
          this.SelectedGameServer.spawn_kill_config[key]
        ) {
          updatedConfig.spawn_kill_config[key] = changedConfig[key];
        }
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers`,
        data: updatedConfig,
      });

      this.$store.commit('UPDATE_SERVER_VALUES', {
        server_id: updatedConfig.server_id,
        value: response.server,
      });

      this.SelectedGameServer = response.server;
      this.updatedSelectedGameServer = JSON.parse(
        JSON.stringify(this.SelectedGameServer)
      );
      this.allowSubmitGeneralChanges = true;
      this.showModal = false;
    },
    async submitKillfeedConfigUpdatedDetails() {
      // disable the button
      this.allowSubmitGeneralChanges = false;

      let updatedConfig = {
        server_id: this.SelectedGameServer.id,
        killfeed_config: {},
      };
      let changedConfig = this.updatedSelectedGameServer.killfeed_config;

      for (let key in changedConfig) {
        if (
          changedConfig[key] !==
          this.SelectedGameServer.killfeed_config[key]
        ) {
          updatedConfig.killfeed_config[key] = changedConfig[key];
        }
      }

      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers`,
        data: updatedConfig,
      });

      this.$store.commit('UPDATE_SERVER_VALUES', {
        server_id: updatedConfig.server_id,
        value: response.server,
      });

      this.SelectedGameServer = response.server;
      this.updatedSelectedGameServer = JSON.parse(
        JSON.stringify(this.SelectedGameServer)
      );
      this.allowSubmitGeneralChanges = true;
      this.showModal = false;
    },

    async submitWebhookConfigUpdatedDetails() {
      // disable the button
      this.allowSubmitGeneralChanges = false;

      let updatedConfig = {
        server_id: this.SelectedGameServer.id,
        webhooks_config: {},
      };
      let changedConfig = this.updatedWebhooksConfig;

      for (let key in changedConfig) {
        if (
          changedConfig[key] !==
          this.initialWebhooksConfig[key]
        ) {
          updatedConfig.webhooks_config[key] = changedConfig[key];
        }
      }

    
      const response = await this.$store.dispatch('sendRequest', {
        method: 'PATCH',
        url: `${this.$store.state.apiUrl}/api/servers`,
        data: updatedConfig,
      });

      if (response) {

        this.$store.commit('UPDATE_SERVER_VALUES', {
          server_id: updatedConfig.server_id,
          value: response.server,
        });
  
        this.SelectedGameServer = response.server;
        this.updatedSelectedGameServer = JSON.parse(
          JSON.stringify(this.SelectedGameServer)
        );
        this.showModal = false;
      }
      this.allowSubmitGeneralChanges = true;
    },
  },

  created() {
    this.getGuildChannelsAndRoles();
  },
};
</script>

<style scoped>
.game-servers-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  row-gap: 24px;
}

.choose-gameserver-dropdown {
  /* Frame 171 */

  box-sizing: border-box;

  display: inline-block;
  padding: 12px 16px;
  gap: 16px;

  max-width: 200px;
  height: 45px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.choose-gameserver-dropdown:focus {
  outline: none;
}

.choose-gameserver-dropdown option {
  color: black;
}

.gsoc-heading {
  text-align: left;
  margin: 0;
}

.gsoc-content {
  text-align: left;
  display: flex;
  gap: 24px;
}

.server-options-sub-heading {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: left;
  color: #ffffff;
}

.gsoc-button {
  display: flex;
  padding: 12px 16px;

  max-width: 200px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  background-color: rgba(195, 218, 252, 0.08);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  /* identical to box height, or 24px */
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.modal-sub-heading {
  /* COMMAND NAME */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 15px;
  /* identical to box height */
  letter-spacing: 0.08em;

  color: #bbced8;
}
.modal-main-heading {
  /* Add Channels */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 2.4rem;
  line-height: 29px;
  /* identical to box height */

  color: #ffffff;

  margin-top: 8px;
}

/* Frame 171 */

.config-option-dropdown {
  box-sizing: border-box;

  display: inline-block;

  padding: 8px 12px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.config-option-input,
.config-option-dropdown {
  width: 165px;
}
.config-option-input {
  background-color: rgba(195, 218, 252, 0.08);
  box-sizing: border-box;

  display: inline-block;

  padding: 8px 12px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
}
.config-option-dropdown:focus,
.config-option-input:focus {
  outline: none;
}

.config-option-dropdown option,
.config-option-input option {
  color: black;
}

.modal-body-cc-inner {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.config-row {
  display: flex;
  justify-content: space-between;
}

.config-option {
  flex-direction: column;
  text-align: left;
  display: flex;
  row-gap: 4px;
}

.config-option label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  /* identical to box height */
  /* letter-spacing: 0.08em; */
  padding-left: 5px;

  color: #bbced8;
}
</style>
