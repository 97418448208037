<template>
  <div class="placement-pings-management-container">
    <div class="management-top">
      <h1 class="content-header">Placement Pings</h1>
      <p class="content-header-sub">
        Manage channel pings for certain item placements
      </p>
    </div>
    <div class="management-lower">
      <select
        name=""
        id=""
        class="choose-shop-item dropdown"
        v-model="selectedPlacementPing"
        @change="
          updateSelectedItemSelect
        "
      >
        <option disabled value="">Edit Placement Ping</option>
        <option
          v-for="placement_ping in placementPingsOriginal"
          :key="placement_ping.id"
          :value="placement_ping"
        >
          {{ placement_ping.item_name }}
        </option>
      </select>
      <button class="btn new-item-btn" @click="openNewItemModal">
        <p>Add New Placement Ping</p>
      </button>
    </div>

    <div class="item-ping-management-section" v-if="selectedPlacementPing">
      <div class="modal-option">
        <label for="item name" class="required-input"
          >Item Name (Exactly like logs)</label
        >
        <input
          type="text"
          class="modal-option-input"
          v-model="selectedPlacementPingUpdated.item_name"
          maxlength="100"
        />
      </div>
      <div class="modal-option">
        <label for="item_usage_before_ping" class=""
          >Item usage before ping
        </label>
        <input
          type="text"
          class="modal-option-input"
          v-model="selectedPlacementPingUpdated.item_usage_before_ping"
          maxlength="100"
        />
      </div>

      <div class="modal-option">
        <label for="item_usage_timeframe" class=""
          >Item usage timeframe (minutes)</label
        >
        <input
          type="number"
          class="modal-option-input"
          v-model="selectedPlacementPingUpdated.item_usage_timeframe"
          min="1"
          max="120"
        />
      </div>
      <div class="modal-option">
        <label for="enabled" class="">Enabled</label>
        <select
          name=""
          class="dropdown"
          v-model="selectedPlacementPingUpdated.enabled"
        >
          <option value="false">False</option>
          <option value="true">True</option>
        </select>
      </div>

      <button class="btn edit-servers-btn" @click="openSelectedServersModal">
        <p>Edit Servers</p>
      </button>

      <div class="btn-container-item-ping-update">
        <button class="modal-btn cancel-btn" @click="discardUpdatedChanges">
          Cancel
        </button>
        <button
          class="modal-btn save-btn"
          @click="saveUpdatedItem"
          :disabled="!itemUpdateBtnEnabled"
        >
          <svg
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <p class="btn-text">Save</p>
        </button>
      </div>
    </div>

    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">Placement Pings</p>
          <p class="modal-main-heading">Add New Placement Ping</p>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-row-container">
          <div class="modal-row">
            <div class="modal-option">
              <label for="item name" class="required-input"
                >Item Name (Exactly like logs)</label
              >
              <input
                type="text"
                class="modal-option-input"
                v-model="newPlacementPing.item_name"
                maxlength="100"
              />
            </div>
            <div class="modal-option">
              <label for="item_usage_before_ping" class=""
                >Item Usage Before Ping</label
              >
              <input
                type="number"
                class="modal-option-input"
                v-model="newPlacementPing.item_usage_before_ping"
                min="0"
                max="50"
              />
            </div>
          </div>
          <div class="modal-row">
            <div class="modal-option">
              <label for="item_usage_timeframe" class=""
                >Item Usage Timeframe (minutes)</label
              >
              <input
                type="number"
                class="modal-option-input"
                v-model="newPlacementPing.item_usage_timeframe"
                min="1"
                max="120"
              />
            </div>
            <div class="modal-option">
              <label for="enabled" class="">Enabled</label>
              <select
                name=""
                class="dropdown"
                v-model="newPlacementPing.enabled"
              >
                <option value="false">False</option>
                <option value="true">True</option>
              </select>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardNewItem">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="saveNewPlacementPing"
            :disabled="!saveNewItemBtnEnabled || !newPlacementPing.item_name"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>

    <Modal
      :show="showSelectedServersModal"
      @update:show="showSelectedServersModal = $event"
    >
      <template v-slot:header-content>
        <div class="modal-header-content-container">
          <p class="modal-sub-heading">Edit Servers</p>
          <p class="modal-main-heading">
            Edit The Servers This Item Is Banned On
          </p>
        </div>
      </template>

      <template v-slot:body-content>
        <div class="modal-body-content-container">
          <div class="modal-body-restricted-servers-container">
            <div
              v-for="server in selectedPlacementPingUpdated.servers"
              :key="server.id"
              class="modal-checkbox-select"
              :class="{
                'active-checked': server.selected,
              }"
            >
              <input
                type="checkbox"
                :id="server.id"
                class="checkbox"
                :checked="server.selected"
                @change="server.selected = !server.selected"
              />
              <label :for="server.id" class="checkbox-label"></label>
              <p class="checkbox-name">{{ server.name }}</p>
            </div>
          </div>
        </div>
      </template>

      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="discardSelectedServerChanges">
            Cancel
          </button>
          <button
            class="modal-btn save-btn"
            @click="saveSelectedServerChanges"
            :disabled="!updateServersBtnEnabled"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save Changes</p>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal.vue';
export default {
  name: 'PlacementPingsManagement',
  components: {
    Modal,
  },

  data() {
    return {
      placementPingsOriginal: [],
      placementPingsCopy: [],
      selectedPlacementPing: '',
      selectedPlacementPingUpdated: {},
      addBtnEnabled: true,
      saveNewItemBtnEnabled: true,
      showSelectedServersModal: false,
      showModal: false,
      updateServersBtnEnabled: true,
      newPlacementPing: {},
      itemUpdateBtnEnabled: true,
    };
  },

  methods: {
    async loadPlacementsData() {
      if (this.$store.state.placementPings.placement_pings) {
        // check if last cache was within 10 minutes
        const lastCache = this.$store.state.placementPings.last_cache_timestamp;
        const currentTime = new Date().getTime();
        const timeDiff = currentTime - lastCache;
        const minutes = Math.floor(timeDiff / 60000);
        if (minutes < 10) {
          this.placementPingsOriginal =
            this.$store.state.placementPings.placement_pings;
          this.placementPingsCopy = JSON.parse(
            JSON.stringify(this.$store.state.placementPings.placement_pings)
          );
          return;
        }
      }

      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        method: 'GET',
        show_message_on_success: false,
      });

      if (response) {
        this.placementPingsOriginal = response.item_auto_pings;
        this.placementPingsCopy = JSON.parse(
          JSON.stringify(response.item_auto_pings)
        );
        this.$store.commit('setPlacementPings', {
          placement_pings: response.item_auto_pings,
          last_cache_timestamp: new Date().getTime(),
        });
      }
    },
    openNewItemModal() {
      this.showModal = true;
    },

    discardSelectedServerChanges() {
      this.selectedPlacementPingUpdated.servers = this.$store.state.client_discord_server.servers.map(
        (server) => {
          return {
            id: server.id,
            name: server.server_identifier,
            selected: this.selectedPlacementPing.servers.some(
              (selectedServer) => selectedServer.id === server.id
            ),
          };
        }
      );
    },

    async saveSelectedServerChanges() {
      this.updateServersBtnEnabled = false;

      const selectedServers = this.selectedPlacementPingUpdated.servers.filter(
        (server) => server.selected
      );

      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        method: 'PUT',
        data: {
          id: this.selectedPlacementPingUpdated.id,
          server_ids: selectedServers.map((server) => server.id),
        },
        show_message_on_success: true,
      });

      if (response) {
        const index = this.placementPingsOriginal.findIndex(
          (ping) => ping.id === response.item_auto_ping.id
        );
        this.placementPingsOriginal[index] = response.item_auto_ping;
        this.placementPingsCopy[index] = response.item_auto_ping;
        this.selectedPlacementPing = response.item_auto_ping;

        this.$store.commit('setPlacementPings', {
          placement_pings: this.placementPingsOriginal,
          last_cache_timestamp: new Date().getTime(),
        });
      }
      this.showSelectedServersModal = false;
      this.updateServersBtnEnabled = true;
    },

    async saveNewPlacementPing() {
      this.saveNewItemBtnEnabled = false;

      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        method: 'POST',
        data: this.newPlacementPing,
        show_message_on_success: true,
      });

      if (response) {
        this.placementPingsOriginal.push(response.item_auto_ping);
        this.placementPingsCopy.push(response.item_auto_ping);
        this.newPlacementPing = {};

        this.$store.commit('setPlacementPings', {
          placement_pings: this.placementPingsOriginal,
          last_cache_timestamp: new Date().getTime(),
        });
      }
      this.showModal = false;
      this.saveNewItemBtnEnabled = true;
    },

    openSelectedServersModal() {
      this.showModal = false;
      this.showSelectedServersModal = true;
    },
    async saveUpdatedItem() {
      this.saveNewItemBtnEnabled = false;

      let changedConfig = {}

      for (let key in this.selectedPlacementPingUpdated) {
        if (this.selectedPlacementPingUpdated[key] !== this.selectedPlacementPing[key] && key !== 'servers') {
          changedConfig[key] = this.selectedPlacementPingUpdated[key]
        }
      }

      console.log(changedConfig)

      // check if there are any changes
      if (Object.keys(changedConfig).length === 0) {
        this.saveNewItemBtnEnabled = true;
        return;
      }

      const response = await this.$store.dispatch('sendRequest', {
        url: `${this.$store.state.apiUrl}/api/item-auto-ping`,
        method: 'PUT',
        data: {
          id: this.selectedPlacementPingUpdated.id,
          ...changedConfig,
        },
        show_message_on_success: true,
      });

      if (response) {
        const index = this.placementPingsOriginal.findIndex(
          (ping) => ping.id === response.item_auto_ping.id
        );
        this.placementPingsOriginal[index] = response.item_auto_ping;
        this.placementPingsCopy[index] = response.item_auto_ping;
        this.selectedPlacementPing = response.item_auto_ping;

        this.$store.commit('setPlacementPings', {
          placement_pings: this.placementPingsOriginal,
          last_cache_timestamp: new Date().getTime(),
        });
      }
    },

    async discardUpdatedChanges() {
      this.selectedPlacementPingUpdated = JSON.parse(
        JSON.stringify(this.selectedPlacementPing)
      );

      this.selectedPlacementPingUpdated.servers = this.$store.state.client_discord_server.servers.map(
        (server) => {
          return {
            id: server.id,
            name: server.server_identifier,
            selected: this.selectedPlacementPing.servers.some(
              (selectedServer) => selectedServer.id === server.id
            ),
          };
        }
      );
    },

    updateSelectedItemSelect() {
      this.selectedPlacementPingUpdated = JSON.parse(
        JSON.stringify(this.selectedPlacementPing)
      );

      console.log(this.selectedPlacementPingUpdated)

      this.selectedPlacementPingUpdated.servers = this.$store.state.client_discord_server.servers.map(
        (server) => {
          return {
            id: server.id,
            name: server.server_identifier,
            selected: this.selectedPlacementPing.servers.some(
              (selectedServer) => selectedServer.id === server.id
            ),
          };
        }
      );

      console.log(this.selectedPlacementPingUpdated.servers)
    },


  },

  created() {
    this.loadPlacementsData();
  },
};
</script>

<style scoped>
.placement-pings-management-container {
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
  width: 100%;
  display: flex;
  padding: 24px;
  flex-direction: column;
  text-align: left;
}
.management-btn {
  display: flex;
  padding: 12px 16px;
  max-width: 200px;
  border: 1px solid rgba(195, 238, 252, 0.16);
  background-color: rgba(195, 218, 252, 0.08);
  border-radius: 12px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 150%;
  text-align: left;
  color: #ffffff;
  cursor: pointer;
}

.management-lower {
  display: flex;
  gap: 24px;
  align-items: center;
}

.new-item-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
}

.edit-servers-btn {
  box-sizing: border-box;
  display: inline-block;
  line-height: 150%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  font-family: 'DM Mono';
  font-size: 1.4em;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  padding: 10px 12px 10px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
  height: fit-content;
  width: fit-content;
}

.item-ping-management-section {
  margin-top: 24px;
  display: grid;
  /* make the grid auto responsive, as many columns as it can fit, each column 200px max */
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  row-gap: 16px;
  align-items: end;
}

.btn-container-item-ping-update {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}
</style>
