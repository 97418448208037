<template>
  <div class="commands-management">
    <div class="commands-management-container">
      <div class="cm-header-container">
        <h1 class="content-header cm-header">Commands</h1>

        <p class="cm-header-text">
          Manage Your Command Permissions, Including Roles and Channels
        </p>
        <select
          name=""
          id=""
          class="choose-commands-dropdown"
          v-model="selectedCommand"
          @change="fetchCommandPermissions"
        >
          <option disabled value="">Choose a command</option>
          <option
            v-for="command in commandsList"
            :key="command"
            :value="command"
          >
            {{ command.command_name }}
          </option>
        </select>
      </div>
      <div class="cm-permissions-container">
        <div class="search-bar">
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 9.09938 14.0158 9.85792 13.7226 10.5657C13.4295 11.2734 12.9998 11.9164 12.4581 12.4581C11.9164 12.9998 11.2734 13.4295 10.5657 13.7226C9.85792 14.0158 9.09938 14.1667 8.33333 14.1667C7.56729 14.1667 6.80875 14.0158 6.10101 13.7226C5.39328 13.4295 4.75022 12.9998 4.20854 12.4581C3.66687 11.9164 3.23719 11.2734 2.94404 10.5657C2.65088 9.85792 2.5 9.09938 2.5 8.33333C2.5 6.78624 3.11458 5.30251 4.20854 4.20854C5.30251 3.11458 6.78624 2.5 8.33333 2.5C9.88043 2.5 11.3642 3.11458 12.4581 4.20854C13.5521 5.30251 14.1667 6.78624 14.1667 8.33333Z"
              stroke="#BBD1D8"
              stroke-opacity="0.64"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>

          <input
            type="text"
            placeholder="Search"
            class="search-input"
            v-model="permissionsBoxSearch"
          />
        </div>

        <div class="cm-permissions-header-container">
          <h1 class="cm-permissions-heading">Current Permissions</h1>
          <button
            class="add-permissions"
            @click="selectedCommand && (showModal = true)"
          >
            <svg
              width="12"
              height="13"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6 0.5C6.22733 0.5 6.44535 0.590306 6.60609 0.751051C6.76684 0.911797 6.85714 1.12981 6.85714 1.35714V5.64286H11.1429C11.3702 5.64286 11.5882 5.73316 11.7489 5.89391C11.9097 6.05465 12 6.27267 12 6.5C12 6.72733 11.9097 6.94535 11.7489 7.10609C11.5882 7.26684 11.3702 7.35714 11.1429 7.35714H6.85714V11.6429C6.85714 11.8702 6.76684 12.0882 6.60609 12.2489C6.44535 12.4097 6.22733 12.5 6 12.5C5.77267 12.5 5.55465 12.4097 5.39391 12.2489C5.23316 12.0882 5.14286 11.8702 5.14286 11.6429V7.35714H0.857143C0.629814 7.35714 0.411797 7.26684 0.251051 7.10609C0.0903058 6.94535 0 6.72733 0 6.5C0 6.27267 0.0903058 6.05465 0.251051 5.89391C0.411797 5.73316 0.629814 5.64286 0.857143 5.64286H5.14286V1.35714C5.14286 1.12981 5.23316 0.911797 5.39391 0.751051C5.55465 0.590306 5.77267 0.5 6 0.5Z"
                fill="#BBCED8"
                fill-opacity="0.64"
              />
            </svg>
            <p class="add-permissions-text">Edit Permissions</p>
          </button>
        </div>
        <div class="current-permissions-box">
          <button
            v-for="permission in selectedCommandPermissions"
            :key="permission.id"
            class="current-permission"
          >
            {{ permission.type == 'channel' ? '#' : '@' }} {{ permission.name }}
          </button>
        </div>

        <div class="current-permissions-footer">
          <button class="modal-btn cancel-btn discard-btn" @click="cancelEdits">
            Discard Changes
          </button>
          <button
            class="modal-btn save-btn save-permissions-btn"
            @click="savePermissions()"
          >
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Save</p>
          </button>
        </div>
      </div>
    </div>
    <Modal :show="showModal" @update:show="showModal = $event">
      <template v-slot:header-content>
        <div class="header-content-container">
          <p class="modal-sub-heading">{{ selectedCommand.command_name }}</p>
          <p class="modal-main-heading">Edit Permissions</p>
          <div class="search-bar sb-modal">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 17.5L12.5 12.5M14.1667 8.33333C14.1667 9.09938 14.0158 9.85792 13.7226 10.5657C13.4295 11.2734 12.9998 11.9164 12.4581 12.4581C11.9164 12.9998 11.2734 13.4295 10.5657 13.7226C9.85792 14.0158 9.09938 14.1667 8.33333 14.1667C7.56729 14.1667 6.80875 14.0158 6.10101 13.7226C5.39328 13.4295 4.75022 12.9998 4.20854 12.4581C3.66687 11.9164 3.23719 11.2734 2.94404 10.5657C2.65088 9.85792 2.5 9.09938 2.5 8.33333C2.5 6.78624 3.11458 5.30251 4.20854 4.20854C5.30251 3.11458 6.78624 2.5 8.33333 2.5C9.88043 2.5 11.3642 3.11458 12.4581 4.20854C13.5521 5.30251 14.1667 6.78624 14.1667 8.33333Z"
                stroke="#BBD1D8"
                stroke-opacity="0.64"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <input
              type="text"
              placeholder="Search"
              class="search-input"
              v-model="modalSearch"
            />
          </div>
        </div>
      </template>
      <template v-slot:body-content>
        <div class="modal-body-container">
          <div class="modal-body-header">
            <p class="mb-heading">Permissions</p>
            <select name="" id="" class="filter-by" v-model="filterBy">
              <option disabled value="">Filter By</option>
              <option value="">No Filter</option>
              <option value="selected">Currently Selected</option>
              <option value="roles">Roles</option>
              <option value="channels">Channels</option>
            </select>
          </div>
          <div class="modal-body-permissions-container">
            <div
              v-for="permission in filteredCommandPermissions"
              :key="permission.id"
              class="modal-permission-select"
              :class="{
                'active-checked': permission.selected,
              }"
            >
              <input
                type="checkbox"
                :id="permission.id"
                class="checkbox"
                :checked="permission.selected"
                @change="handleCheckboxChange(permission)"
              />
              <label :for="permission.id" class="checkbox-label"></label>
              <svg
                v-if="permission.type == 'channel'"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.48947 1.12342C5.55757 0.714839 5.28155 0.328418 4.87297 0.260322C4.4644 0.192225 4.07798 0.46824 4.00988 0.876818L3.55877 3.58349H1.4165C1.00229 3.58349 0.666504 3.91928 0.666504 4.33349C0.666504 4.7477 1.00229 5.08349 1.4165 5.08349H3.30877L3.00322 6.91674H0.75C0.335786 6.91674 0 7.25253 0 7.66674C0 8.08096 0.335786 8.41674 0.75 8.41674H2.75322L2.34321 10.8768C2.27512 11.2854 2.55113 11.6718 2.95971 11.7399C3.36829 11.808 3.75471 11.532 3.8228 11.1234L4.27392 8.41674H6.75371L6.3437 10.8768C6.2756 11.2854 6.55162 11.6718 6.9602 11.7399C7.36877 11.808 7.7552 11.532 7.82329 11.1234L8.2744 8.41674H10.0833C10.4975 8.41674 10.8333 8.08096 10.8333 7.66674C10.8333 7.25253 10.4975 6.91674 10.0833 6.91674H8.5244L8.82995 5.08349H10.7498C11.1641 5.08349 11.4998 4.7477 11.4998 4.33349C11.4998 3.91928 11.1641 3.58349 10.7498 3.58349H9.07995L9.48996 1.12342C9.55805 0.714839 9.28204 0.328418 8.87346 0.260322C8.46488 0.192225 8.07846 0.46824 8.01037 0.876818L7.55926 3.58349H5.07946L5.48947 1.12342ZM7.00371 6.91674L7.30926 5.08349H4.82946L4.52392 6.91674H7.00371Z"
                  fill="#BBCED8"
                  fill-opacity="0.64"
                />
              </svg>
              <svg
                v-if="permission.type == 'role'"
                width="7"
                height="10"
                viewBox="0 0 7 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.476 9.628C3.58 9.628 2.832 9.424 2.232 9.016C1.64 8.616 1.196 8.056 0.9 7.336C0.604 6.616 0.456 5.792 0.456 4.864C0.456 4.032 0.552 3.316 0.744 2.716C0.944 2.116 1.212 1.624 1.548 1.24C1.884 0.848 2.264 0.559999 2.688 0.375999C3.112 0.191999 3.548 0.0999994 3.996 0.0999994C4.836 0.0999994 5.5 0.308 5.988 0.724C6.476 1.14 6.72 1.736 6.72 2.512V7.216H5.808L5.712 6.64H5.664C5.544 6.84 5.38 7.004 5.172 7.132C4.972 7.26 4.72 7.324 4.416 7.324C3.824 7.324 3.332 7.108 2.94 6.676C2.548 6.244 2.352 5.636 2.352 4.852C2.352 4.068 2.544 3.464 2.928 3.04C3.32 2.616 3.82 2.404 4.428 2.404C4.732 2.404 4.984 2.456 5.184 2.56C5.384 2.656 5.544 2.784 5.664 2.944V2.512C5.664 2.064 5.512 1.712 5.208 1.456C4.904 1.2 4.5 1.072 3.996 1.072C3.588 1.072 3.196 1.196 2.82 1.444C2.452 1.684 2.148 2.084 1.908 2.644C1.676 3.196 1.56 3.936 1.56 4.864C1.56 5.544 1.664 6.172 1.872 6.748C2.088 7.324 2.412 7.788 2.844 8.14C3.276 8.484 3.82 8.656 4.476 8.656H6.18V9.628H4.476ZM4.536 6.424C4.864 6.424 5.132 6.3 5.34 6.052C5.556 5.796 5.664 5.4 5.664 4.864C5.664 4.32 5.556 3.924 5.34 3.676C5.132 3.428 4.864 3.304 4.536 3.304C4.216 3.304 3.948 3.428 3.732 3.676C3.516 3.924 3.408 4.32 3.408 4.864C3.408 5.4 3.516 5.796 3.732 6.052C3.948 6.3 4.216 6.424 4.536 6.424Z"
                  fill="#BBCED8"
                  fill-opacity="0.64"
                />
              </svg>

              <p class="permission-name">{{ permission.name }}</p>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer-content>
        <div class="modal-footer-content-container">
          <button class="modal-btn cancel-btn" @click="cancelEdits">
            Cancel
          </button>
          <button class="modal-btn save-btn" @click="finishEdits">
            <svg
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.83325 8.66675L6.49992 11.3334L13.1666 4.66675"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p class="btn-text">Done</p>
          </button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import axios from 'axios';
import Modal from './Modal.vue';

export default {
  components: { Modal },

  data() {
    return {
      selectedCommand: '',

      permissionsToAdd: [],
      permissionsToRemove: [],
      permissionsBoxSearch: '',
      roles: [],
      channels: [],
      search: '',
      filterBy: '',
      showModal: false,
      permissions: [],
      modalSearch: '',
    };
  },
  computed: {
    allRolesAndChannels() {
      return [
        ...this.roles.map(role => ({ ...role, type: 'role', selected: false })),
        ...this.channels.map(channel => ({
          ...channel,
          type: 'channel',
          selected: false,
        })),
      ];
    },

    filteredCommandPermissions() {
      // use the filterBy value to filter the selectedCommandPermissions
      const filteredPerms = this.permissions.filter(permission => {
        if (this.filterBy === 'selected') {
          return permission.selected;
        } else if (this.filterBy === 'roles') {
          return permission.type === 'role';
        } else if (this.filterBy === 'channels') {
          return permission.type === 'channel';
        } else {
          return true;
        }
      });

      // add the search functionality
      return filteredPerms.filter(permission =>
        permission.name.toLowerCase().includes(this.modalSearch.toLowerCase())
      );
    },

    selectedCommandPermissions() {
      let permissions = this.permissions.filter(
        permission => permission.selected
      );

      let filteredPermissions = permissions.filter(permission => {
        if (this.permissionsBoxSearch) {
          return permission.name
            .toLowerCase()
            .includes(this.permissionsBoxSearch.toLowerCase());
        } else {
          return true;
        }
      });
      return filteredPermissions;
    },

    commandsList() {
      return this.$store.state.client_discord_server.commands_list;
    },
    guildId() {
      return this.$store.state.client_discord_server.discord_server_id;
    },
    applicationId() {
      return this.$store.state.application_id;
    },
  },
  methods: {
    handleCheckboxChange(permission) {
      const index = this.permissions.findIndex(p => p.id === permission.id);
      if (index !== -1) {
        let newSelected = !this.permissions[index].selected;
        this.permissions[index].selected = !this.permissions[index].selected;

        if (newSelected) {
          this.permissionsToAdd.push(permission);
          // check if the permission is in the permissionsToRemove array
          const index = this.permissionsToRemove.findIndex(
            p => p.id === permission.id
          );
          if (index !== -1) {
            this.permissionsToRemove.splice(index, 1);
          }
        } else {
          this.permissionsToRemove.push(permission);
          // check if the permission is in the permissionsToAdd array
          const index = this.permissionsToAdd.findIndex(
            p => p.id === permission.id
          );
          if (index !== -1) {
            this.permissionsToAdd.splice(index, 1);
          }
        }
      }
    },

    async savePermissions() {

      if (this.$store.state.client_discord_server &&
        this.$store.state.client_discord_server.admin) {
          if (this.$store.state.client_discord_server.admin.management_role !== 'owner' && this.$store.state.client_discord_server.admin.management_role !==  'manager') {

            return this.$store.commit('setStatusMessage', {
              text: 'You do not have permission to edit commands',
              type: 'error',
            });
          }
        }
      // make sure the permissionsToAdd and permissionsToRemove arrays are unique
      this.permissionsToAdd = this.permissionsToAdd.filter(
        (permission, index, self) =>
          index === self.findIndex(p => p.id === permission.id)
      );

      this.permissionsToRemove = this.permissionsToRemove.filter(
        (permission, index, self) =>
          index === self.findIndex(p => p.id === permission.id)
      );

      // make sure add permissions and remove perssions are both not empty
      if (
        this.permissionsToAdd.length === 0 &&
        this.permissionsToRemove.length === 0
      ) {
        this.$store.commit('setStatusMessage', {
          text: 'No changes to save',
          type: 'error',
        });
        return;
      }

      // get the id and type of the permissions
      let currentpermissions = this.permissions
        .filter(permission => permission.selected)
        .map(permission => ({
          id: permission.id,
          type:
            permission.type === 'role'
              ? 1
              : permission.type === 'channel'
              ? 3
              : undefined,
          permission: true,
        }));

      const response = await axios.put(
        `https://discord.com/api/v10/applications/1161237266207875102/guilds/${this.guildId}/commands/${this.selectedCommand.command_id}/permissions`,
        {
          permissions: currentpermissions,
        },
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.discord_access_token}`,
          },
        }
      );

      if (response.status === 200) {
        this.$store.commit('setStatusMessage', {
          text: 'Permissions updated successfully',
          type: 'success',
        });
      }
    },
    formatRoleChannelName(name, type) {
      if (type === 'role' && !name.startsWith('@')) {
        return '@ ' + name;
      } else if (type === 'channel') {
        return '# ' + name;
      } else {
        return name;
      }
    },
    removePermission(id) {
      this.commandPermissions = this.commandPermissions.filter(
        permission => permission.id !== id
      );
    },
    async fetchCommandPermissions() {
      if (!this.selectedCommand) {
        return [];
      }

      try {
        const response = await axios.get(
          `https://discord.com/api/v10/applications/1161237266207875102/guilds/${this.guildId}/commands/${this.selectedCommand.command_id}/permissions`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.discord_access_token}`,
            },
          }
        );

        const commandPermissions = response.data.permissions;

        this.permissions = this.permissions.map(permission => ({
          ...permission,
          selected: commandPermissions.some(p => p.id === permission.id),
        }));
      } catch (error) {
        if (error.response.status === 404) {
        } else {
          alert('An error occurred while fetching command permissions.');
        }
        this.permissions = this.permissions.map(permission => ({
          ...permission,
          selected: false,
        }));
      }
    },
    async getGuildChannelsAndRoles() {
      try {
        const response = await axios.get(
          `${this.$store.state.apiUrl}/api/dashboard/discord/guilds/roles-and-channels`,
          {
            params: {
              guild_id: this.guildId,
            },
          }
        );

        

        if (
          Array.isArray(response.data.data.roles) &&
          Array.isArray(response.data.data.channels)
        ) {
          this.permissions = [
            ...response.data.data.roles.map(role => ({
              ...role,
              type: 'role',
              selected: false,
            })),
            ...response.data.data.channels.map(channel => ({
              ...channel,
              type: 'channel',
              selected: false,
            })),
          ];
        } else {
          console.error('Roles or Channels are not arrays');
        }
      } catch (error) {
        console.error('Error fetching guild channels and roles:', error);
      }
    },

    cancelEdits() {
      // remove the permissions that were added
      this.permissionsToAdd.forEach(permission => {
        const index = this.permissions.findIndex(p => p.id === permission.id);
        if (index !== -1) {
          this.permissions[index].selected = false;
        }
      });

      this.permissionsToRemove.forEach(permission => {
        const index = this.permissions.findIndex(p => p.id === permission.id);
        if (index !== -1) {
          this.permissions[index].selected = true;
        }
      });

      this.permissionsToAdd = [];
      this.showModal = false;
      this.permissionsToRemove = [];
    },

    finishEdits() {
      // add the permissions that were added
      this.permissionsToAdd.forEach(permission => {
        const index = this.permissions.findIndex(p => p.id === permission.id);
        if (index !== -1) {
          this.permissions[index].selected = true;
        }
      });

      this.showModal = false;
    },
  },

  created() {
    this.getGuildChannelsAndRoles();
  },
};
</script>

<style scoped>
.commands-management {
  height: 90%;
}

.commands-management-container {
  /* Frame 46 */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;

  max-width: 800px;
  /* height: 100%; */
  background: rgba(187, 218, 255, 0.04);
  border: 1px solid rgba(187, 218, 255, 0.08);
  border-radius: 16px;
}

.cm-header-container {
  padding: 24px;

  border-bottom: 1px solid rgba(187, 218, 255, 0.08);

  text-align: left;
  width: 100%;
}

.cm-header {
  margin-bottom: 8px;
}

.cm-header-text {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #bbd1d8;
  margin-bottom: 24px;
}

.choose-commands-dropdown {
  /* Frame 171 */

  box-sizing: border-box;

  display: inline-block;
  padding: 12px 16px;
  gap: 16px;

  height: 45px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  width: 100%;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 16px center; /* Position it 16px from the right edge */
  padding-right: 36px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.choose-commands-dropdown:focus {
  outline: none;
}

.choose-commands-dropdown option {
  color: black;
}

.cm-permissions-container {
  padding: 24px;
  width: 100%;
  text-align: left;
}
.search-bar {
  position: relative;
  width: 200px; /* Adjust as needed */
}

.search-bar svg {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
}

.search-input {
  width: 100%;
  height: 35px; /* Adjust as needed */
  padding: 0 12px;
  padding-left: 12px; /* Make room for the search icon */
  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;
  background: linear-gradient(0deg, #101721, #101721),
    linear-gradient(0deg, rgba(195, 238, 252, 0.16), rgba(195, 238, 252, 0.16));

  color: #ffffff;
}
.search-input::placeholder {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #bbd1d8;
  opacity: 0.64;
}

.search-input:focus {
  outline: none;
}

.cm-permissions-container {
  height: 100%;
}

.cm-permissions-heading {
  /* Active Channels */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  /* identical to box height, or 21px */

  color: #bbced8;
}

.current-permissions-box {
  /* max-width: 450px; */
  background: rgba(187, 218, 255, 0.04);
  height: 240px;
  border-radius: 12px;
  padding: 12px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start; /* Add this line */
  width: 100%;
  gap: 12px;
}

/* customise the scrollbar */
/* width */
.current-permissions-box::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.current-permissions-box::-webkit-scrollbar-track {
  background: #1d2632;
  border-radius: 12px;
}

/* Handle */
.current-permissions-box::-webkit-scrollbar-thumb {
  background: rgba(187, 218, 255, 0.04);

  border-radius: 12px;
}

/* Handle on hover */
.current-permissions-box::-webkit-scrollbar-thumb:hover {
  background: rgba(187, 218, 255, 0.04);
}
.current-permission {
  /* Frame 112 */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 24px 12px 16px;
  gap: 12px;

  outline: none;
  border: none;
  cursor: pointer;
  height: 45px;

  background: rgba(66, 208, 156, 0.24);
  border-radius: 12px;
  width: fit-content;

  /* @ */

  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: 0.08em;

  color: #42d09c;
}

.cm-permissions-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add-permissions {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px 8px 12px;
  background: #c3dafc29;
  border-radius: 19px;
  border: none;
  cursor: pointer;
  transform: translateY(-2px);
}

.add-permissions-text {
  font-family: 'DM Mono';
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem !important;
  line-height: 16px;
  /* identical to box height */
  letter-spacing: 0.08em;

  color: #bbced8;
}


.sb-modal {
  width: 100%;
  margin-top: 24px;
}

.modal-body-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mb-heading {
  font-family: Inter;
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(187, 206, 216, 1);
}
.dropdown {
  background: rgba(187, 218, 255, 0.04);
}

.filter-by {
  /* Frame 171 */

  box-sizing: border-box;

  display: inline-block;
  gap: 16px;

  border: 1px solid rgba(195, 238, 252, 0.16);
  border-radius: 12px;

  /* width: 30px; */
  padding: 4px 12px;

  font-family: 'Inter';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(187, 206, 216, 1);

  /* identical to box height, or 21px */

  color: #ffffff;
  appearance: none; /* Remove default arrow */
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 16 17" fill="none"><path d="M12.6666 6.5L7.99992 11.1667L3.33325 6.5" stroke="white" stroke-opacity="0.8" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 8px center; /* Position it 16px from the right edge */
  padding-right: 24px; /* Add padding to prevent the text from overlapping the arrow */
  cursor: pointer;
  background-color: rgba(195, 218, 252, 0.08);
}

.filter-by:focus {
  outline: none;
}

.filter-by option {
  color: black;
}

.modal-permission-select {
  padding: 12px;
  border-radius: 8px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 12px;
  font-family: 'DM Mono';
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-align: left;
  color: rgba(187, 206, 216, 1);
}

.modal-body-container {
  height: 350px;
}
.modal-body-permissions-container {
  margin-top: 8px;
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding-right: 8px;
}
.modal-body-permissions-container::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.modal-body-permissions-container::-webkit-scrollbar-track {
  background: #1d2632;
  border-radius: 12px;
}

/* Handle */
.modal-body-permissions-container::-webkit-scrollbar-thumb {
  background: rgba(187, 218, 255, 0.04);

  border-radius: 12px;
}

/* Handle on hover */
.modal-body-permissions-container::-webkit-scrollbar-thumb:hover {
  background: rgba(187, 218, 255, 0.04);
}

.active-checked {
  background: rgba(195, 218, 252, 0.08);
}
.checkbox {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox-label {
  width: 16px;
  height: 16px;
  border: 1px solid #bbced8a3;
  border-radius: 4px;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.checkbox:checked + .checkbox-label {
  background: #42d09c3d;

  border: 2px solid #42d09ca3;
  background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M13.3657%204.23447C13.5157%204.3845%2013.5999%204.58794%2013.5999%204.80007C13.5999%205.0122%2013.5157%205.21565%2013.3657%205.36567L6.96568%2011.7657C6.81566%2011.9156%206.61221%2011.9999%206.40008%2011.9999C6.18795%2011.9999%205.9845%2011.9156%205.83448%2011.7657L2.63448%208.56567C2.48876%208.41479%202.40812%208.21271%202.40994%208.00295C2.41177%207.7932%202.4959%207.59255%202.64423%207.44422C2.79255%207.29589%202.9932%207.21176%203.20296%207.20993C3.41272%207.20811%203.6148%207.28875%203.76568%207.43447L6.40008%2010.0689L12.2345%204.23447C12.3845%204.0845%2012.588%204.00024%2012.8001%204.00024C13.0122%204.00024%2013.2157%204.0845%2013.3657%204.23447Z%22%20fill%3D%22%2342D09C%22%2F%3E%3C%2Fsvg%3E');
  background-repeat: no-repeat;
  background-position: center;
}

.modal-footer-content-container {
  display: flex;
  gap: 8px;
}

.current-permissions-footer {
  padding: 24px;
  display: flex;
  padding-bottom: 0px;
  align-items: center;
  justify-content: right;
  padding-right: 0px;
  gap: 8px;
}

.discard-btn,
.save-permissions-btn {
  height: 32px;
  font-family: 'DM Mono';
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.08em;
  text-align: left;
  width: fit-content;
  padding: 8px 16px 8px 16px;
}
</style>
